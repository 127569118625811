/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../../../graphql/generated';

import { INotificationCategoryFieldsFragment } from './notificationCategoryFieldsFragment.generated';
import { gql } from '@apollo/client';
import { NotificationCategoryFieldsFragmentDoc } from './notificationCategoryFieldsFragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export type IUpdateEmailNotificationSettingsMutationVariables = Types.Exact<{
    input: Types.IUpdateEmailNotificationSettingsInput;
}>;

export type IUpdateEmailNotificationSettingsMutation = {
    __typename?: 'Mutation';
    updateEmailNotificationSettings: {
        __typename?: 'EmailNotificationSettings';
        notificationCategories: Array<
            { __typename?: 'EmailNotificationCategory' } & INotificationCategoryFieldsFragment
        >;
    };
};

export const UpdateEmailNotificationSettingsDocument = gql`
    mutation updateEmailNotificationSettings($input: UpdateEmailNotificationSettingsInput!) {
        updateEmailNotificationSettings(input: $input) {
            notificationCategories {
                ...NotificationCategoryFields
            }
        }
    }
    ${NotificationCategoryFieldsFragmentDoc}
`;
export type IUpdateEmailNotificationSettingsMutationFn = Apollo.MutationFunction<
    IUpdateEmailNotificationSettingsMutation,
    IUpdateEmailNotificationSettingsMutationVariables
>;

/**
 * __useUpdateEmailNotificationSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateEmailNotificationSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmailNotificationSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmailNotificationSettingsMutation, { data, loading, error }] = useUpdateEmailNotificationSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEmailNotificationSettingsMutation(
    baseOptions?: Apollo.MutationHookOptions<
        IUpdateEmailNotificationSettingsMutation,
        IUpdateEmailNotificationSettingsMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        IUpdateEmailNotificationSettingsMutation,
        IUpdateEmailNotificationSettingsMutationVariables
    >(UpdateEmailNotificationSettingsDocument, options);
}
export type UpdateEmailNotificationSettingsMutationHookResult = ReturnType<
    typeof useUpdateEmailNotificationSettingsMutation
>;
export type UpdateEmailNotificationSettingsMutationResult =
    Apollo.MutationResult<IUpdateEmailNotificationSettingsMutation>;
export type UpdateEmailNotificationSettingsMutationOptions = Apollo.BaseMutationOptions<
    IUpdateEmailNotificationSettingsMutation,
    IUpdateEmailNotificationSettingsMutationVariables
>;
