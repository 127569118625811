import React from 'react';
import { useToaster, CommonProp, generate, Checkbox } from '@acin/ui-core';
import * as SC from './UserPreferencesModal.styled';
import { IEmailNotificationCategory, IUpdatedEmailNotificationCategory } from '../../../graphql/generated';
import { useGetEmailNotificationSettingsQuery } from '../graphql/mutations/getEmailNotificationSettings.generated';
import { useUpdateEmailNotificationSettingsMutation } from '../graphql/mutations/updateEmailNotificationSettings.generated';

export interface EmailPreferencesModalProps extends CommonProp {
    className?: string;
    onClose: () => void;
    show: boolean;
}

export const EmailPreferencesModal: React.FC<EmailPreferencesModalProps> = (props) => {
    const { className, show, onClose } = props;
    const { onAddToast } = useToaster();

    const { data: getData, loading: getLoading, refetch } = useGetEmailNotificationSettingsQuery();
    const [updateEmailNotificationSettings, { data: updateData, loading: updateLoading }] =
        useUpdateEmailNotificationSettingsMutation({
            onCompleted: () => {
                onAddToast({
                    id: `email-settings-updated`,
                    title: 'Email settings updated',
                    status: 'success',
                    removeAt: +new Date() + 10000,
                });
            },
            onError: (error) => {
                onAddToast({
                    id: `email-settings-failed-to-update`,
                    title: 'Email settings failed to update',
                    status: 'error',
                    message: error.message,
                    removeAt: +new Date() + 10000,
                });
            },
        });

    const [notificationSettings, setNotificationSettings] = React.useState<IEmailNotificationCategory[]>([]);
    const [initialSettings, setInitialSettings] = React.useState<IEmailNotificationCategory[]>([]);

    const categoryCheckboxes = notificationSettings.map((setting) => {
        return (
            <Checkbox
                isChecked={setting.isEnabled}
                onChange={(value) => onCategoryChange(setting.code, value)}
                label={setting.name}
                size="small"
            />
        );
    });

    const onCategoryChange = (code: string, isEnabled: boolean) => {
        const newNotificationSettings = notificationSettings.map((notification: IEmailNotificationCategory) => {
            if (notification.code === code) {
                return { ...notification, isEnabled };
            }
            return notification;
        });
        setNotificationSettings(newNotificationSettings);
    };

    const onUpdateClick = () => {
        const updatedNotificationCategories: IUpdatedEmailNotificationCategory[] = notificationSettings.map(
            (setting) => {
                return {
                    code: setting.code,
                    isEnabled: setting.isEnabled,
                };
            },
        );
        updateEmailNotificationSettings({
            variables: {
                input: {
                    updatedNotificationCategories,
                },
            },
        });
    };

    const hasChanges = () => {
        if (initialSettings.length !== notificationSettings.length) return true;

        return notificationSettings.some((currentSetting, index) => {
            return currentSetting.isEnabled !== initialSettings[index].isEnabled;
        });
    };

    React.useEffect(() => {
        if (show) {
            refetch();
        }
    }, [show, refetch]);

    React.useEffect(() => {
        const fetchedSettings = getData?.getEmailNotificationSettings.notificationCategories || [];
        setNotificationSettings(fetchedSettings);
        setInitialSettings(fetchedSettings);
    }, [getData]);

    React.useEffect(() => {
        const fetchedSettings = updateData?.updateEmailNotificationSettings.notificationCategories || [];
        setNotificationSettings(fetchedSettings);
        setInitialSettings(fetchedSettings);
    }, [updateData]);

    return (
        <SC.UserPreferencesModal
            data-testid={generate(['email-settings-modal'], props)}
            className={className}
            title="Update Email Settings"
            show={show}
            onClose={onClose}
            footerButtonPrimary={{
                label: 'Update Settings',
                disabled: getLoading || updateLoading || !hasChanges(),
                onClick: onUpdateClick,
            }}
        >
            <SC.Description weight={500}>
                Please select the email notifications you wish to receive. You can update your preferences at any time.
            </SC.Description>

            {categoryCheckboxes}
        </SC.UserPreferencesModal>
    );
};
