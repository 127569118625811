import styled from 'styled-components';
import { Modal, Text, View } from '@acin/ui-core';

// This named style should be the wrapper for the component i.e. the module
export const UserPreferencesModal = styled(Modal)``;

export const Description = styled(Text)`
    color: ${(p) => p.theme.colors.text.getValue(1)};
    margin-top: ${(p) => p.theme.spacing.getSize(1)};
    margin-bottom: ${(p) => p.theme.spacing.getSize(6)};
`;

export const Title = styled(Text)`
    color: ${(p) => p.theme.colors.text.getValue(1)};
`;

export const ThumbnailWrapper = styled(View)`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-top: ${(p) => p.theme.spacing.getSize(4)};
`;

export const Version = styled(Text)`
    margin: ${(p) => p.theme.spacing.getSize(4)} 0;
`;
