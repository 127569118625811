/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../../../graphql/generated';

import { INotificationCategoryFieldsFragment } from './notificationCategoryFieldsFragment.generated';
import { gql } from '@apollo/client';
import { NotificationCategoryFieldsFragmentDoc } from './notificationCategoryFieldsFragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export type IGetEmailNotificationSettingsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type IGetEmailNotificationSettingsQuery = {
    __typename?: 'Query';
    getEmailNotificationSettings: {
        __typename?: 'EmailNotificationSettings';
        notificationCategories: Array<
            { __typename?: 'EmailNotificationCategory' } & INotificationCategoryFieldsFragment
        >;
    };
};

export const GetEmailNotificationSettingsDocument = gql`
    query getEmailNotificationSettings {
        getEmailNotificationSettings {
            notificationCategories {
                ...NotificationCategoryFields
            }
        }
    }
    ${NotificationCategoryFieldsFragmentDoc}
`;

/**
 * __useGetEmailNotificationSettingsQuery__
 *
 * To run a query within a React component, call `useGetEmailNotificationSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmailNotificationSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmailNotificationSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEmailNotificationSettingsQuery(
    baseOptions?: Apollo.QueryHookOptions<
        IGetEmailNotificationSettingsQuery,
        IGetEmailNotificationSettingsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<IGetEmailNotificationSettingsQuery, IGetEmailNotificationSettingsQueryVariables>(
        GetEmailNotificationSettingsDocument,
        options,
    );
}
export function useGetEmailNotificationSettingsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        IGetEmailNotificationSettingsQuery,
        IGetEmailNotificationSettingsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<IGetEmailNotificationSettingsQuery, IGetEmailNotificationSettingsQueryVariables>(
        GetEmailNotificationSettingsDocument,
        options,
    );
}
export type GetEmailNotificationSettingsQueryHookResult = ReturnType<typeof useGetEmailNotificationSettingsQuery>;
export type GetEmailNotificationSettingsLazyQueryHookResult = ReturnType<
    typeof useGetEmailNotificationSettingsLazyQuery
>;
export type GetEmailNotificationSettingsQueryResult = Apollo.QueryResult<
    IGetEmailNotificationSettingsQuery,
    IGetEmailNotificationSettingsQueryVariables
>;
