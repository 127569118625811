/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../../../graphql/generated';

import { gql } from '@apollo/client';
export type INotificationCategoryFieldsFragment = {
    __typename?: 'EmailNotificationCategory';
    name: string;
    code: string;
    isEnabled: boolean;
};

export const NotificationCategoryFieldsFragmentDoc = gql`
    fragment NotificationCategoryFields on EmailNotificationCategory {
        name
        code
        isEnabled
    }
`;
