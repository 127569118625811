export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type FieldWrapper<T> = T;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export enum IAdpFileStage {
  Upload = 'UPLOAD',
  Scoping = 'SCOPING',
  Pipeline = 'PIPELINE',
  Matching = 'MATCHING',
  Publish = 'PUBLISH',
  Rematching = 'REMATCHING',
  Republish = 'REPUBLISH'
}

export enum IAdpFileStatus {
  ToConnect = 'TO_CONNECT',
  InProgress = 'IN_PROGRESS',
  Approved = 'APPROVED',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  DataInvalid = 'DATA_INVALID',
  Rejected = 'REJECTED'
}

export type IAccount = {
  __typename?: 'Account';
  id: FieldWrapper<Scalars['ID']>;
  name: FieldWrapper<Scalars['String']>;
  code?: Maybe<FieldWrapper<Scalars['String']>>;
  users: Array<Maybe<FieldWrapper<IUser>>>;
  status?: Maybe<FieldWrapper<Scalars['Boolean']>>;
  permittedRiskInventories: Array<Maybe<FieldWrapper<IAccountRiskInventory>>>;
  availableMatchers: Array<FieldWrapper<IUser>>;
  availableApprovers: Array<FieldWrapper<IUser>>;
  defaultMatcher?: Maybe<FieldWrapper<IUser>>;
  defaultApprover?: Maybe<FieldWrapper<IUser>>;
  dataMatcher: Array<FieldWrapper<IDataMatcher>>;
  riskInventories: Array<FieldWrapper<IRiskInventory>>;
};

export type IAccountRiskInventory = {
  __typename?: 'AccountRiskInventory';
  id: FieldWrapper<Scalars['ID']>;
  name: FieldWrapper<Scalars['String']>;
  description?: Maybe<FieldWrapper<Scalars['String']>>;
  sourceId: FieldWrapper<Scalars['String']>;
  accounts: Array<Maybe<FieldWrapper<IAccount>>>;
};

export type IAcinControl = {
  __typename?: 'AcinControl';
  id: FieldWrapper<Scalars['ID']>;
  title: FieldWrapper<Scalars['String']>;
  description: FieldWrapper<Scalars['String']>;
  objectives: FieldWrapper<Scalars['String']>;
  review?: Maybe<FieldWrapper<Scalars['String']>>;
  identify?: Maybe<FieldWrapper<Scalars['String']>>;
  actions?: Maybe<FieldWrapper<Scalars['String']>>;
  controlSource: FieldWrapper<Scalars['String']>;
  controlType: FieldWrapper<Scalars['String']>;
  controlOwner: FieldWrapper<Scalars['String']>;
  controlFrequency: FieldWrapper<Scalars['String']>;
  controlCode: FieldWrapper<Scalars['String']>;
  relatedRegulations: Array<FieldWrapper<IRegulation>>;
  relatedRiskInventories: Array<FieldWrapper<IRiskInventory>>;
  totalRelatedRiskInventories: FieldWrapper<Scalars['Int']>;
  totalRelatedRegulations: FieldWrapper<Scalars['Int']>;
  updatedAt: FieldWrapper<Scalars['String']>;
  riskInventory?: Maybe<FieldWrapper<IRiskInventory>>;
  reasonCode?: Maybe<FieldWrapper<IReasonCode>>;
  automation?: Maybe<FieldWrapper<Scalars['String']>>;
  keywords: Array<FieldWrapper<IKeyword>>;
  products: Array<FieldWrapper<IProduct>>;
  relatedRisks: Array<FieldWrapper<IAcinRisk>>;
  nss?: Maybe<FieldWrapper<INetworkSignalScore>>;
};

export type IAcinControlsByRisk = {
  __typename?: 'AcinControlsByRisk';
  id: FieldWrapper<Scalars['ID']>;
  keyStats?: Maybe<Array<FieldWrapper<IKeyStat>>>;
  availableFilters?: Maybe<FieldWrapper<IFilters>>;
  filters: FieldWrapper<IFilterResponse>;
  risks?: Maybe<FieldWrapper<IAcinRiskPage>>;
};


export type IAcinControlsByRiskRisksArgs = {
  filters?: Maybe<IFilterInput>;
};

export type IAcinRisk = {
  __typename?: 'AcinRisk';
  id: FieldWrapper<Scalars['ID']>;
  title: FieldWrapper<Scalars['String']>;
  riskCode: FieldWrapper<Scalars['String']>;
  riskSource: FieldWrapper<Scalars['String']>;
  description?: Maybe<FieldWrapper<Scalars['String']>>;
  riskGroup: FieldWrapper<IAcinRiskGroup>;
  updatedAt: FieldWrapper<Scalars['String']>;
  controlCount: FieldWrapper<Scalars['Int']>;
  relatedReasonCodes: Array<FieldWrapper<IReasonCode>>;
  relatedRiskIntelligence: Array<FieldWrapper<IRelatedRiskIntelligence>>;
  relatedRegulations: Array<FieldWrapper<IRegulation>>;
  relatedControls: Array<FieldWrapper<IAcinControl>>;
  relatedRiskInventories: Array<FieldWrapper<IRiskInventory>>;
};

export type IAcinRiskGroup = {
  __typename?: 'AcinRiskGroup';
  id: FieldWrapper<Scalars['ID']>;
  name: FieldWrapper<Scalars['String']>;
  risks: Array<FieldWrapper<IAcinRisk>>;
};

export type IAcinRiskPage = {
  __typename?: 'AcinRiskPage';
  sortInfo: FieldWrapper<ISortInfo>;
  nodes: Array<FieldWrapper<IAcinRisk>>;
};

export type IActiveChartItem = {
  label?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
};

export type IAddAccountInput = {
  name: Scalars['String'];
  code?: Maybe<Scalars['String']>;
};

export type IAddUserInput = {
  name: Scalars['String'];
  email: Scalars['String'];
  accountId: Scalars['ID'];
};

export type IAlertMessage = {
  __typename?: 'AlertMessage';
  title: FieldWrapper<Scalars['String']>;
  message?: Maybe<FieldWrapper<Scalars['String']>>;
  type: FieldWrapper<IAlertMessageType>;
};

export enum IAlertMessageType {
  Info = 'INFO',
  Warning = 'WARNING',
  Success = 'SUCCESS',
  Danger = 'DANGER'
}

export type IAllReportsInput = {
  filters?: Maybe<IFilterInput>;
  sorting?: Maybe<ISortInput>;
  pagination?: Maybe<IPaginationInput>;
  searchTerm?: Maybe<Scalars['String']>;
  pageCode?: Maybe<IPageCode>;
};

export type IAllReportsPage = {
  __typename?: 'AllReportsPage';
  nodes: Array<FieldWrapper<IReport>>;
  pagination?: Maybe<FieldWrapper<IPageInfo>>;
  activeSorting?: Maybe<FieldWrapper<ISortField>>;
  unfilteredTotal?: Maybe<FieldWrapper<Scalars['Int']>>;
};

export type IAllReportsQuickFilter = {
  __typename?: 'AllReportsQuickFilter';
  reportType?: Maybe<FieldWrapper<IReportType>>;
  filter?: Maybe<FieldWrapper<IFilterPayloadValue>>;
};

export type IAllReportsQuickFilterPage = {
  __typename?: 'AllReportsQuickFilterPage';
  filters: Array<FieldWrapper<IAllReportsQuickFilter>>;
};

export enum IApprovalWorkflow {
  Dashboard = 'DASHBOARD',
  SubmitReview = 'SUBMIT_REVIEW',
  Unassigned = 'UNASSIGNED'
}

export type IApproveMatchAcinControlMatchInput = {
  acinControlId?: Maybe<Scalars['ID']>;
  internalComment?: Maybe<Scalars['String']>;
  externalComment?: Maybe<Scalars['String']>;
  status: IDataMatchingMatchStatus;
};

export type IApproveRejectMatchAcinControlInput = {
  dataMatcherId: Scalars['ID'];
  dataMatcherClientControlId: Scalars['ID'];
  matches: Array<IApproveMatchAcinControlMatchInput>;
};

export type IApproveRejectMissingAcinControlInput = {
  dataMatcherId: Scalars['ID'];
  acinControlId: Scalars['ID'];
  internalComment?: Maybe<Scalars['String']>;
  externalComment?: Maybe<Scalars['String']>;
  status: IDataMatchingMatchStatus;
};

export type IAvailableChart = {
  __typename?: 'AvailableChart';
  id: FieldWrapper<Scalars['String']>;
  type: FieldWrapper<Scalars['String']>;
  name: FieldWrapper<Scalars['String']>;
};

export type IAvailableCompletenessReport = {
  __typename?: 'AvailableCompletenessReport';
  id: FieldWrapper<Scalars['String']>;
  name: FieldWrapper<Scalars['String']>;
  count: FieldWrapper<Scalars['Int']>;
};

export type IBenchmarkOverview = {
  __typename?: 'BenchmarkOverview';
  networkAlignmentScore: FieldWrapper<Scalars['Float']>;
  networkAlignmentMaxScore: FieldWrapper<Scalars['Float']>;
  charts: Array<FieldWrapper<INetworkAlignmentScoreProgress>>;
};

export type IBubbleChart = {
  __typename?: 'BubbleChart';
  colorPaletteName: FieldWrapper<IColorPaletteName>;
  series: Array<FieldWrapper<IBubbleChartSeries>>;
  keys: Array<FieldWrapper<IChartKey>>;
};

export type IBubbleChartSeries = {
  __typename?: 'BubbleChartSeries';
  itemId?: Maybe<FieldWrapper<Scalars['ID']>>;
  label: FieldWrapper<Scalars['String']>;
  values: Array<FieldWrapper<IBubbleChartValue>>;
  type?: Maybe<FieldWrapper<IDashboardItemType>>;
};

export enum IBubbleChartShape {
  Circle = 'CIRCLE',
  Diamond = 'DIAMOND'
}

export type IBubbleChartValue = {
  __typename?: 'BubbleChartValue';
  shape: FieldWrapper<IBubbleChartShape>;
  colorPalette?: Maybe<FieldWrapper<IColorPalette>>;
  value: FieldWrapper<Scalars['Float']>;
  size?: Maybe<FieldWrapper<Scalars['Float']>>;
  label: FieldWrapper<Scalars['String']>;
  description?: Maybe<Array<FieldWrapper<Scalars['String']>>>;
};

export type IBulkApproveInput = {
  accountCode: Scalars['String'];
  matchingJobId: Scalars['String'];
  clusterIds: Array<Scalars['String']>;
};

export type IBusinessLine = {
  __typename?: 'BusinessLine';
  id: FieldWrapper<Scalars['ID']>;
  title: FieldWrapper<Scalars['String']>;
};

export enum IChart_Value_Format {
  Months = 'MONTHS',
  Number = 'NUMBER'
}

export type ICalibrateDownloadFile = {
  __typename?: 'CalibrateDownloadFile';
  id: FieldWrapper<Scalars['ID']>;
  filename: FieldWrapper<Scalars['String']>;
  type: FieldWrapper<IDownloadFileType>;
  status: FieldWrapper<ICalibrateDownloadFileStatus>;
  progressPercentage?: Maybe<FieldWrapper<Scalars['Int']>>;
  fileURL?: Maybe<FieldWrapper<Scalars['String']>>;
};

export enum ICalibrateDownloadFileStatus {
  Progress = 'PROGRESS',
  Failure = 'FAILURE',
  Processed = 'PROCESSED',
  Downloaded = 'DOWNLOADED'
}

export type ICalibrateReportInput = {
  title?: Maybe<Scalars['String']>;
  variant: ITableVariant;
  configuration?: Maybe<Array<IFilterInputGroup>>;
  filters?: Maybe<IFilterInput>;
};

export type IChartAsTableCell = {
  __typename?: 'ChartAsTableCell';
  id: FieldWrapper<Scalars['String']>;
  key: FieldWrapper<Scalars['String']>;
  text?: Maybe<FieldWrapper<Scalars['String']>>;
};

export type IChartAsTableCellGroup = {
  __typename?: 'ChartAsTableCellGroup';
  name: FieldWrapper<Scalars['String']>;
  color: FieldWrapper<IColorPalette>;
  cells: Array<FieldWrapper<IChartAsTableHeaderCell>>;
};

export type IChartAsTableHeader = {
  __typename?: 'ChartAsTableHeader';
  cells: Array<FieldWrapper<IChartAsTableCell>>;
  groups: Array<FieldWrapper<IChartAsTableCellGroup>>;
};

export type IChartAsTableHeaderCell = {
  __typename?: 'ChartAsTableHeaderCell';
  id?: Maybe<FieldWrapper<Scalars['String']>>;
  key: FieldWrapper<Scalars['String']>;
  color?: Maybe<FieldWrapper<IColorPalette>>;
  text?: Maybe<FieldWrapper<Scalars['String']>>;
};

export type IChartAsTableInput = {
  configuration?: Maybe<Array<IFilterInputGroup>>;
  filters?: Maybe<IFilterInput>;
  sort?: Maybe<ISortInput>;
  breadcrumb?: Maybe<Array<IChartBreadcrumbInput>>;
  variant?: Maybe<ITableVariant>;
};

export type IChartAsTableResult = {
  __typename?: 'ChartAsTableResult';
  title?: Maybe<FieldWrapper<Scalars['String']>>;
  header?: Maybe<FieldWrapper<IChartAsTableHeader>>;
  rows: Array<FieldWrapper<IChartAsTableRow>>;
  sort: FieldWrapper<ISortField>;
  breadcrumb?: Maybe<Array<FieldWrapper<IChartBreadcrumb>>>;
  levels?: Maybe<FieldWrapper<IFilterPayloadInputGroup>>;
  pagination?: Maybe<FieldWrapper<IPageInfo>>;
};

export type IChartAsTableRow = {
  __typename?: 'ChartAsTableRow';
  id: FieldWrapper<Scalars['String']>;
  cells: Array<FieldWrapper<IChartAsTableCell>>;
};

export type IChartBreadcrumb = {
  __typename?: 'ChartBreadcrumb';
  id: FieldWrapper<Scalars['String']>;
  label: FieldWrapper<Scalars['String']>;
  configuration: Array<FieldWrapper<IFilterPayloadInputGroup>>;
  filters?: Maybe<Array<FieldWrapper<IFilterPayloadInputGroup>>>;
};

export type IChartBreadcrumbInput = {
  id: Scalars['String'];
  label: Scalars['String'];
  configuration: Array<IFilterInputGroup>;
  filters?: Maybe<Array<IFilterInputGroup>>;
};

export type IChartData = ITableRefresherBarChart;

export type IChartFiltersResult = {
  __typename?: 'ChartFiltersResult';
  levels?: Maybe<FieldWrapper<IFilterPayloadInputGroup>>;
};

export type IChartInput = {
  configuration?: Maybe<Array<IFilterInputGroup>>;
  filters?: Maybe<IFilterInput>;
  breadcrumb?: Maybe<Array<IChartBreadcrumbInput>>;
  variant?: Maybe<ITableVariant>;
};

export type IChartKey = {
  __typename?: 'ChartKey';
  label: FieldWrapper<Scalars['String']>;
  key?: Maybe<FieldWrapper<Scalars['String']>>;
  description?: Maybe<FieldWrapper<Scalars['String']>>;
  count?: Maybe<FieldWrapper<Scalars['Int']>>;
  colorPalette?: Maybe<FieldWrapper<IColorPalette>>;
};

export type IChartResult = {
  __typename?: 'ChartResult';
  title?: Maybe<FieldWrapper<Scalars['String']>>;
  charts?: Maybe<Array<FieldWrapper<IChartData>>>;
  breadcrumb?: Maybe<Array<FieldWrapper<IChartBreadcrumb>>>;
  pagination?: Maybe<FieldWrapper<IPageInfo>>;
};

export type IChartSummary = {
  __typename?: 'ChartSummary';
  data: Array<FieldWrapper<IChartSummaryItem>>;
};

export type IChartSummaryItem = {
  __typename?: 'ChartSummaryItem';
  value: FieldWrapper<Scalars['Int']>;
  label: FieldWrapper<Scalars['String']>;
  colorPalette: FieldWrapper<IColorPalette>;
};

export type IChartTableCategory = {
  __typename?: 'ChartTableCategory';
  id: FieldWrapper<Scalars['String']>;
  label: FieldWrapper<Scalars['String']>;
  colorPalette: FieldWrapper<IColorPalette>;
};

export type IChartTableInstance = {
  __typename?: 'ChartTableInstance';
  category?: Maybe<FieldWrapper<Scalars['String']>>;
  value: FieldWrapper<Scalars['Int']>;
  info?: Maybe<FieldWrapper<IDrillDownInfo>>;
  id: FieldWrapper<Scalars['String']>;
};

export type IChartTableResult = {
  __typename?: 'ChartTableResult';
  colorPaletteName?: Maybe<FieldWrapper<Scalars['String']>>;
  categories: Array<FieldWrapper<IChartTableCategory>>;
  nameHeader: FieldWrapper<ITableRefresherBarChartHeader>;
  valueHeader: FieldWrapper<ITableRefresherBarChartHeader>;
  rows: Array<FieldWrapper<IChartTableRow>>;
  breadcrumb?: Maybe<Array<FieldWrapper<IChartBreadcrumb>>>;
};

export type IChartTableRow = {
  __typename?: 'ChartTableRow';
  id: FieldWrapper<Scalars['String']>;
  isAcin?: Maybe<FieldWrapper<Scalars['Boolean']>>;
  label: FieldWrapper<Scalars['String']>;
  info?: Maybe<FieldWrapper<IDrillDownInfo>>;
  instances: Array<FieldWrapper<IChartTableInstance>>;
};

export type IClientControl = {
  __typename?: 'ClientControl';
  id: FieldWrapper<Scalars['ID']>;
  title: FieldWrapper<Scalars['String']>;
  description?: Maybe<FieldWrapper<Scalars['String']>>;
  objectives?: Maybe<FieldWrapper<Scalars['String']>>;
  controlCode?: Maybe<FieldWrapper<Scalars['String']>>;
  controlSource?: Maybe<FieldWrapper<Scalars['String']>>;
  controlType?: Maybe<FieldWrapper<Scalars['String']>>;
  controlOwner?: Maybe<FieldWrapper<Scalars['String']>>;
  controlFrequency?: Maybe<FieldWrapper<Scalars['String']>>;
  controlId?: Maybe<FieldWrapper<Scalars['String']>>;
  updatedAt?: Maybe<FieldWrapper<Scalars['String']>>;
  riskInventory?: Maybe<FieldWrapper<IRiskInventory>>;
  reasonCode?: Maybe<FieldWrapper<IReasonCode>>;
  relatedRiskInventories: Array<FieldWrapper<IRiskInventory>>;
  totalRelatedRiskInventories: FieldWrapper<Scalars['Int']>;
  automation?: Maybe<FieldWrapper<Scalars['String']>>;
  keywords: Array<FieldWrapper<IKeyword>>;
  controlQualityScore?: Maybe<FieldWrapper<IControlQualityScore>>;
  relatedRisks: Array<FieldWrapper<IClientRisk>>;
  relatedBusinessLines: Array<FieldWrapper<IBusinessLine>>;
};

export type IClientControlData = {
  __typename?: 'ClientControlData';
  nodes: Array<FieldWrapper<IClientControl>>;
};

export type IClientControlsByRisk = {
  __typename?: 'ClientControlsByRisk';
  id: FieldWrapper<Scalars['ID']>;
  keyStats?: Maybe<Array<FieldWrapper<IKeyStat>>>;
  availableFilters?: Maybe<FieldWrapper<IFilters>>;
  filters: FieldWrapper<IFilterResponse>;
  risks?: Maybe<FieldWrapper<IClientRiskPage>>;
};


export type IClientControlsByRiskRisksArgs = {
  filters?: Maybe<IFilterInput>;
};

export type IClientRisk = {
  __typename?: 'ClientRisk';
  id: FieldWrapper<Scalars['ID']>;
  title: FieldWrapper<Scalars['String']>;
  description?: Maybe<FieldWrapper<Scalars['String']>>;
  riskCode?: Maybe<FieldWrapper<Scalars['String']>>;
  riskLevel?: Maybe<FieldWrapper<Scalars['String']>>;
  riskReason?: Maybe<FieldWrapper<Scalars['String']>>;
  riskSource?: Maybe<FieldWrapper<Scalars['String']>>;
  controlCount: FieldWrapper<Scalars['Int']>;
  updatedAt?: Maybe<FieldWrapper<Scalars['String']>>;
  relatedReasonCodes: Array<FieldWrapper<IReasonCode>>;
  relatedRiskIntelligence: Array<FieldWrapper<IRelatedRiskIntelligence>>;
  relatedControls: Array<FieldWrapper<IClientControl>>;
  relatedBusinessLines: Array<FieldWrapper<IBusinessLine>>;
};

export type IClientRiskPage = {
  __typename?: 'ClientRiskPage';
  sortInfo: FieldWrapper<ISortInfo>;
  nodes: Array<FieldWrapper<IClientRisk>>;
};

export type IColorPalette = {
  __typename?: 'ColorPalette';
  paletteIndex?: Maybe<FieldWrapper<Scalars['Int']>>;
  shade?: Maybe<FieldWrapper<Scalars['Int']>>;
};

export enum IColorPaletteName {
  DataVis = 'DATA_VIS',
  DataVisWithMeaning = 'DATA_VIS_WITH_MEANING'
}

export type ICompareControl = ICompareControlOneToOne | ICompareControlOneToMany | ICompareControlManyToOne | ICompareEmpty;

export type ICompareControlManyToOne = {
  __typename?: 'CompareControlManyToOne';
  id: FieldWrapper<Scalars['ID']>;
  controlsA?: Maybe<Array<FieldWrapper<IClientControl>>>;
  controlB?: Maybe<FieldWrapper<IAcinControl>>;
  message?: Maybe<FieldWrapper<IAlertMessage>>;
};

export type ICompareControlOneToMany = {
  __typename?: 'CompareControlOneToMany';
  id: FieldWrapper<Scalars['ID']>;
  controlA?: Maybe<FieldWrapper<IClientControl>>;
  controlsB?: Maybe<Array<FieldWrapper<IAcinControl>>>;
  message?: Maybe<FieldWrapper<IAlertMessage>>;
};

export type ICompareControlOneToOne = {
  __typename?: 'CompareControlOneToOne';
  id: FieldWrapper<Scalars['ID']>;
  controlA?: Maybe<FieldWrapper<IClientControl>>;
  controlB?: Maybe<FieldWrapper<IAcinControl>>;
  highlightedFields: Array<FieldWrapper<Scalars['String']>>;
  message?: Maybe<FieldWrapper<IAlertMessage>>;
};

export type ICompareControlPage = {
  __typename?: 'CompareControlPage';
  availableRiskInventories?: Maybe<Array<FieldWrapper<IRiskInventory>>>;
  activeRiskInventory?: Maybe<FieldWrapper<IRiskInventory>>;
  compare?: Maybe<FieldWrapper<ICompareControl>>;
  relationshipInsightContext?: Maybe<FieldWrapper<IRelationshipInsightContext>>;
};

export type ICompareEmpty = {
  __typename?: 'CompareEmpty';
  id: FieldWrapper<Scalars['ID']>;
  message?: Maybe<FieldWrapper<IAlertMessage>>;
};

export type ICompareRisk = ICompareRiskOneToMany | ICompareRiskManyToOne | ICompareEmpty;

export type ICompareRiskManyToOne = {
  __typename?: 'CompareRiskManyToOne';
  id: FieldWrapper<Scalars['ID']>;
  risksA?: Maybe<Array<FieldWrapper<IClientRisk>>>;
  riskB?: Maybe<FieldWrapper<IAcinRisk>>;
  message?: Maybe<FieldWrapper<IAlertMessage>>;
};

export type ICompareRiskOneToMany = {
  __typename?: 'CompareRiskOneToMany';
  id: FieldWrapper<Scalars['ID']>;
  riskA?: Maybe<FieldWrapper<IClientRisk>>;
  risksB?: Maybe<Array<FieldWrapper<IAcinRisk>>>;
  message?: Maybe<FieldWrapper<IAlertMessage>>;
};

export type ICompletenessOverTimeCategory = {
  __typename?: 'CompletenessOverTimeCategory';
  id: FieldWrapper<Scalars['ID']>;
  count: FieldWrapper<Scalars['Int']>;
  colorPalette: FieldWrapper<IColorPalette>;
  label: FieldWrapper<Scalars['String']>;
  percentage: FieldWrapper<Scalars['Int']>;
};

export type ICompletenessOverTimeChartData = {
  __typename?: 'CompletenessOverTimeChartData';
  id: FieldWrapper<Scalars['ID']>;
  colorLibrary: FieldWrapper<Scalars['String']>;
  date: FieldWrapper<Scalars['String']>;
  total: FieldWrapper<Scalars['Int']>;
  categories: Array<FieldWrapper<ICompletenessOverTimeCategory>>;
};

export type ICompletenessOverTimeComparison = {
  __typename?: 'CompletenessOverTimeComparison';
  id: FieldWrapper<Scalars['ID']>;
  count: FieldWrapper<Scalars['Int']>;
  colorPalette: FieldWrapper<IColorPalette>;
  label: FieldWrapper<Scalars['String']>;
  percentage: FieldWrapper<Scalars['Int']>;
  trend?: Maybe<FieldWrapper<ISentiment>>;
  comparisonCount?: Maybe<FieldWrapper<Scalars['Int']>>;
  comparisonPercentage?: Maybe<FieldWrapper<Scalars['Int']>>;
};

export type ICompletenessOverTimeComparisonTable = {
  __typename?: 'CompletenessOverTimeComparisonTable';
  totalCount: FieldWrapper<Scalars['Int']>;
  totalPercentage: FieldWrapper<Scalars['Int']>;
  categories: Array<Maybe<FieldWrapper<ICompletenessOverTimeComparison>>>;
};

export type ICompletenessOverTimeInput = {
  filters?: Maybe<IFilterInput>;
};

export type ICompletenessOverTimeReport = {
  __typename?: 'CompletenessOverTimeReport';
  id: FieldWrapper<Scalars['ID']>;
};

export type ICompletenessOverTimeReportResult = {
  __typename?: 'CompletenessOverTimeReportResult';
  chart: Array<FieldWrapper<ICompletenessOverTimeChartData>>;
  table: FieldWrapper<ICompletenessOverTimeComparisonTable>;
  availableFilters?: Maybe<FieldWrapper<IFilters>>;
};

export type ICompletenessReport = {
  __typename?: 'CompletenessReport';
  id: FieldWrapper<Scalars['ID']>;
  configurationTitle?: Maybe<FieldWrapper<Scalars['String']>>;
  availableConfiguration?: Maybe<FieldWrapper<IFilters>>;
  useNewTemplate?: Maybe<FieldWrapper<Scalars['Boolean']>>;
};

export type ICompletenessReportControlListPage = {
  __typename?: 'CompletenessReportControlListPage';
  title: FieldWrapper<Scalars['String']>;
  subTitle: FieldWrapper<Scalars['String']>;
  groups: Array<FieldWrapper<IControlListPageGroupings>>;
};

export type ICompletenessReportControlListPageGroup = {
  __typename?: 'CompletenessReportControlListPageGroup';
  id: FieldWrapper<Scalars['ID']>;
  title: FieldWrapper<Scalars['String']>;
  subTitle?: Maybe<FieldWrapper<Scalars['String']>>;
  count: FieldWrapper<Scalars['Int']>;
  subGroups: Array<FieldWrapper<ICompletenessReportControlListPageSubGroup>>;
};

export type ICompletenessReportControlListPageSubGroup = {
  __typename?: 'CompletenessReportControlListPageSubGroup';
  id: FieldWrapper<Scalars['ID']>;
  key?: Maybe<FieldWrapper<Scalars['String']>>;
  title: FieldWrapper<Scalars['String']>;
  description: FieldWrapper<Scalars['String']>;
  count: FieldWrapper<Scalars['Int']>;
  colorPalette?: Maybe<FieldWrapper<IColorPalette>>;
  data: Array<FieldWrapper<IControl>>;
};

export type ICompletenessReportResult = {
  __typename?: 'CompletenessReportResult';
  id: FieldWrapper<Scalars['ID']>;
  availableFilters?: Maybe<FieldWrapper<IFilters>>;
  tableChart?: Maybe<FieldWrapper<ICompletenessReportResultTableChart>>;
  controlList?: Maybe<FieldWrapper<ICompletenessReportControlListPage>>;
  availableReports?: Maybe<Array<Maybe<FieldWrapper<IAvailableCompletenessReport>>>>;
  preventExport?: Maybe<FieldWrapper<Scalars['Boolean']>>;
  filters: FieldWrapper<IFilterResponse>;
};

export type ICompletenessReportResultTableChart = {
  __typename?: 'CompletenessReportResultTableChart';
  chartTitle: FieldWrapper<Scalars['String']>;
  chart: FieldWrapper<ITableRefresherBarChart>;
};

export type IConfiguratorOptionsInput = {
  filters?: Maybe<Array<IFilterInputGroup>>;
};

export type IConfiguratorOptionsLabels = {
  __typename?: 'ConfiguratorOptionsLabels';
  groupKey: FieldWrapper<Scalars['String']>;
  label: FieldWrapper<Scalars['String']>;
};

export type IConfiguratorReportInput = {
  name: Scalars['String'];
  description: Scalars['String'];
  shareWithOrganisation: Scalars['Boolean'];
  configuration?: Maybe<Array<IFilterInputGroup>>;
  filters?: Maybe<IFilterInput>;
  table?: Maybe<IConfiguratorReportTableOptionsInput>;
};

export type IConfiguratorReportResult = {
  __typename?: 'ConfiguratorReportResult';
  id: FieldWrapper<Scalars['ID']>;
  name: FieldWrapper<Scalars['String']>;
  description?: Maybe<FieldWrapper<Scalars['String']>>;
  shareWithOrganisation?: Maybe<FieldWrapper<Scalars['Boolean']>>;
  configuration?: Maybe<Array<FieldWrapper<IFilterPayloadInputGroup>>>;
  filters?: Maybe<FieldWrapper<IFilterPayload>>;
  table?: Maybe<FieldWrapper<IConfiguratorReportTableOptions>>;
  configuratorLabels: Array<FieldWrapper<IConfiguratorOptionsLabels>>;
  author?: Maybe<FieldWrapper<IUser>>;
  quickFilters: Array<FieldWrapper<IAllReportsQuickFilter>>;
  pageCode?: Maybe<FieldWrapper<IPageCode>>;
};

export type IConfiguratorReportTableOptions = {
  __typename?: 'ConfiguratorReportTableOptions';
  variant?: Maybe<FieldWrapper<ITableVariant>>;
};

export type IConfiguratorReportTableOptionsInput = {
  variant?: Maybe<ITableVariant>;
};

export type IConnectionUser = {
  __typename?: 'ConnectionUser';
  id: FieldWrapper<Scalars['String']>;
  name: FieldWrapper<Scalars['String']>;
  email?: Maybe<FieldWrapper<Scalars['String']>>;
};

export type IConnectionUsersInput = {
  filters?: Maybe<IFilterInput>;
};

export type IConnectionUsersResponse = {
  __typename?: 'ConnectionUsersResponse';
  users: Array<FieldWrapper<IConnectionUser>>;
};

export type IControl = IAcinControl | IClientControl;

export type IControlAlignmentOverviewChart = {
  __typename?: 'ControlAlignmentOverviewChart';
  id: FieldWrapper<Scalars['ID']>;
  name: FieldWrapper<Scalars['String']>;
  description: FieldWrapper<Scalars['String']>;
  requiresClarificationKey: FieldWrapper<IChartKey>;
  data: Array<FieldWrapper<IControlAlignmentOverviewChartValue>>;
  info?: Maybe<FieldWrapper<IControlAlignmentOverviewChartInfo>>;
};

export type IControlAlignmentOverviewChartCollection = {
  __typename?: 'ControlAlignmentOverviewChartCollection';
  name?: Maybe<FieldWrapper<Scalars['String']>>;
  totalControls: FieldWrapper<Scalars['Int']>;
  items: Array<FieldWrapper<IControl>>;
};

export type IControlAlignmentOverviewChartInfo = {
  __typename?: 'ControlAlignmentOverviewChartInfo';
  riskInventoryId?: Maybe<FieldWrapper<Scalars['ID']>>;
};

export type IControlAlignmentOverviewChartValue = {
  __typename?: 'ControlAlignmentOverviewChartValue';
  name: FieldWrapper<Scalars['String']>;
  description: FieldWrapper<Scalars['String']>;
  total: FieldWrapper<Scalars['Int']>;
  colorPalette: FieldWrapper<IColorPalette>;
  collection: Array<FieldWrapper<IControlAlignmentOverviewChartCollection>>;
};

export type IControlComparisonDataInput = {
  id: Scalars['String'];
  relationshipType: Scalars['String'];
  relationshipInstanceId: Scalars['String'];
};

export type IControlHistory = {
  __typename?: 'ControlHistory';
  nodes: Array<FieldWrapper<IControlHistoryTable>>;
  activeSorting?: Maybe<FieldWrapper<ISortField>>;
  pagination?: Maybe<FieldWrapper<IPageInfo>>;
};

export type IControlHistoryAttributeChange = {
  __typename?: 'ControlHistoryAttributeChange';
  isLatest?: Maybe<FieldWrapper<Scalars['Boolean']>>;
  value?: Maybe<FieldWrapper<Scalars['String']>>;
};

export type IControlHistoryInput = {
  id: Scalars['ID'];
  pagination?: Maybe<IPaginationInput>;
  sorting?: Maybe<ISortInput>;
};

export type IControlHistoryTable = {
  __typename?: 'ControlHistoryTable';
  id?: Maybe<FieldWrapper<Scalars['String']>>;
  date?: Maybe<FieldWrapper<Scalars['String']>>;
  riskInventoryName?: Maybe<FieldWrapper<Scalars['String']>>;
  attributeChange?: Maybe<FieldWrapper<IControlHistoryAttributeChange>>;
};

export type IControlInspectorView = {
  __typename?: 'ControlInspectorView';
  id: FieldWrapper<Scalars['ID']>;
  compare?: Maybe<FieldWrapper<ICompareControl>>;
};

export type IControlInstanceOptionsInput = {
  id: Scalars['String'];
  insightBy: IFilterInputGroup;
};

export type IControlListPageGroupings = ICompletenessReportControlListPageGroup | ICompletenessReportControlListPageSubGroup;

export enum IControlQualityScore {
  Unclear = 'UNCLEAR',
  Clear = 'CLEAR'
}

export type ICreateDataMatcherInput = {
  accountId: Scalars['ID'];
  fileId: Scalars['ID'];
  riskInventoryId: Scalars['ID'];
  matcherId: Scalars['ID'];
  approverId: Scalars['ID'];
};

export type ICreateReportInput = {
  name: Scalars['String'];
  description: Scalars['String'];
  shareWithOrganisation: Scalars['Boolean'];
  configuration: IFilterInput;
  filters?: Maybe<IFilterInput>;
};

export type IDashboardControlAlignment = {
  __typename?: 'DashboardControlAlignment';
  title: FieldWrapper<Scalars['String']>;
  subTitle: FieldWrapper<Scalars['String']>;
  chart?: Maybe<FieldWrapper<IDonutChart>>;
};

export type IDashboardControlsByRiskInput = {
  filters?: Maybe<IFilterInput>;
};

export enum IDashboardItemType {
  AcinControl = 'ACIN_CONTROL',
  ClientControl = 'CLIENT_CONTROL',
  AcinRisk = 'ACIN_RISK',
  ClientRisk = 'CLIENT_RISK'
}

export type IDashboardNetworkAlignment = {
  __typename?: 'DashboardNetworkAlignment';
  title: FieldWrapper<Scalars['String']>;
  chart?: Maybe<FieldWrapper<IHorizontalDumbbellPlotChart>>;
};

export type IDashboardReport = {
  __typename?: 'DashboardReport';
  id: FieldWrapper<Scalars['ID']>;
  code: FieldWrapper<Scalars['String']>;
};

export type IDashboardReports = {
  __typename?: 'DashboardReports';
  reports: Array<FieldWrapper<IDashboardReport>>;
};

export enum IDataFormat {
  Number = 'NUMBER',
  DateTime = 'DATE_TIME',
  Text = 'TEXT'
}

export type IDataMatcher = {
  __typename?: 'DataMatcher';
  id: FieldWrapper<Scalars['ID']>;
  riskInventory: FieldWrapper<IRiskInventory>;
  matchers: Array<FieldWrapper<IUser>>;
  approvers: Array<FieldWrapper<IUser>>;
  account: FieldWrapper<IAccount>;
  matchCounts: FieldWrapper<IDataMatcherMatches>;
  assignedTo: FieldWrapper<IDataMatcherAssignedTo>;
  matchingStatus: FieldWrapper<IDataMatchingStatus>;
  totalUnassignedNetworkControls: FieldWrapper<Scalars['Int']>;
  nextWorkflow?: Maybe<FieldWrapper<IDataMatcherMatchingWorkflow>>;
  availableFilters: FieldWrapper<IFilters>;
  file: FieldWrapper<IDataMatchingFile>;
};

export type IDataMatcherAcinControl = {
  __typename?: 'DataMatcherAcinControl';
  id: FieldWrapper<Scalars['ID']>;
  control: FieldWrapper<IAcinControl>;
  status: FieldWrapper<IDataMatchingMatchStatus>;
  reasonCode: FieldWrapper<Scalars['String']>;
  missing?: Maybe<FieldWrapper<IDataMatcherAcinControlPreliminaryMissing>>;
};

export type IDataMatcherAcinControlPreliminaryMissing = {
  __typename?: 'DataMatcherAcinControlPreliminaryMissing';
  internalComment?: Maybe<Array<FieldWrapper<IDataMatcherComment>>>;
  externalComment?: Maybe<FieldWrapper<IDataMatcherComment>>;
  status: FieldWrapper<IDataMatchingMatchStatus>;
  approvedRejectedAt?: Maybe<FieldWrapper<Scalars['String']>>;
};

export type IDataMatcherAcinControlSuggestion = {
  __typename?: 'DataMatcherAcinControlSuggestion';
  id: FieldWrapper<Scalars['ID']>;
  control: FieldWrapper<IAcinControl>;
  matchScore?: Maybe<FieldWrapper<IDataMatcherMatchScore>>;
  reasonCode: FieldWrapper<Scalars['String']>;
  totalInventoryMatches?: Maybe<FieldWrapper<Scalars['Int']>>;
  inventoryMatches?: Maybe<Array<FieldWrapper<Scalars['String']>>>;
};

export enum IDataMatcherAssignedTo {
  Matcher = 'MATCHER',
  Approver = 'APPROVER'
}

export type IDataMatcherClientControl = {
  __typename?: 'DataMatcherClientControl';
  id: FieldWrapper<Scalars['ID']>;
  control: FieldWrapper<IDataMatcherUploadedClientControl>;
  status: FieldWrapper<IDataMatchingMatchStatus>;
  totalInternalComments: FieldWrapper<Scalars['Int']>;
  totalMatchedAcinControls: FieldWrapper<Scalars['Int']>;
  totalInventoryMatches?: Maybe<FieldWrapper<Scalars['Int']>>;
  matchScore?: Maybe<FieldWrapper<IDataMatcherMatchScore>>;
  relationshipCount?: Maybe<FieldWrapper<IDataMatchingRelationshipCount>>;
  matches?: Maybe<Array<FieldWrapper<IDataMatcherMatchRecord>>>;
};

export type IDataMatcherClientControlHistoricalAcinMatch = {
  __typename?: 'DataMatcherClientControlHistoricalAcinMatch';
  name?: Maybe<FieldWrapper<Scalars['String']>>;
  code?: Maybe<FieldWrapper<Scalars['String']>>;
  reasonCode: FieldWrapper<Scalars['String']>;
};

export type IDataMatcherClientControlMatchingHistory = {
  __typename?: 'DataMatcherClientControlMatchingHistory';
  activeRiskInventory?: Maybe<FieldWrapper<IDataMatcherRiskInventoryMatchingHistory>>;
  otherRiskInventories?: Maybe<Array<FieldWrapper<IDataMatcherRiskInventoryMatchingHistory>>>;
};

export type IDataMatcherClientControlMatchingHistoryGroup = {
  __typename?: 'DataMatcherClientControlMatchingHistoryGroup';
  date: FieldWrapper<Scalars['String']>;
  matches: Array<FieldWrapper<IDataMatcherClientControlHistoricalAcinMatch>>;
};

export type IDataMatcherClientControlMatchingHistoryInput = {
  dataMatcherClientControlId: Scalars['String'];
  dataMatcherId: Scalars['String'];
};

export type IDataMatcherClientControlSuggestion = {
  __typename?: 'DataMatcherClientControlSuggestion';
  id: FieldWrapper<Scalars['ID']>;
  control: FieldWrapper<IDataMatcherUploadedClientControl>;
  matchScore?: Maybe<FieldWrapper<IDataMatcherMatchScore>>;
  reasonCode: FieldWrapper<Scalars['String']>;
};

export type IDataMatcherComment = {
  __typename?: 'DataMatcherComment';
  id: FieldWrapper<Scalars['ID']>;
  user: FieldWrapper<IUser>;
  comment: FieldWrapper<Scalars['String']>;
  createdAt: FieldWrapper<Scalars['String']>;
};

export type IDataMatcherControl = IDataMatcherClientControl | IDataMatcherAcinControl;

export type IDataMatcherMatchRecord = {
  __typename?: 'DataMatcherMatchRecord';
  id: FieldWrapper<Scalars['ID']>;
  reasonCode: FieldWrapper<Scalars['String']>;
  matchedAcinControl?: Maybe<FieldWrapper<IAcinControl>>;
  internalComment?: Maybe<Array<FieldWrapper<IDataMatcherComment>>>;
  externalComment?: Maybe<FieldWrapper<IDataMatcherComment>>;
  status: FieldWrapper<IDataMatchingMatchStatus>;
  approvedRejectedAt?: Maybe<FieldWrapper<Scalars['String']>>;
};

export type IDataMatcherMatchScore = {
  __typename?: 'DataMatcherMatchScore';
  score: FieldWrapper<Scalars['Float']>;
  highlight: FieldWrapper<Scalars['Boolean']>;
};

export type IDataMatcherMatches = {
  __typename?: 'DataMatcherMatches';
  total: FieldWrapper<Scalars['Int']>;
  matched: FieldWrapper<Scalars['Int']>;
  approved: FieldWrapper<Scalars['Int']>;
  rejected: FieldWrapper<Scalars['Int']>;
};

export type IDataMatcherMatchingWorkflow = IDataMatchingControlsWorkflow;

export type IDataMatcherRiskInventoryMatchingHistory = {
  __typename?: 'DataMatcherRiskInventoryMatchingHistory';
  riskInventory: FieldWrapper<IRiskInventoryHistory>;
  matchingHistory: Array<FieldWrapper<IDataMatcherClientControlMatchingHistoryGroup>>;
};

export type IDataMatcherTab = {
  __typename?: 'DataMatcherTab';
  id: FieldWrapper<Scalars['String']>;
  dataMatching: FieldWrapper<IDataMatchingUploadsPage>;
  count: FieldWrapper<Scalars['Int']>;
};

export type IDataMatcherUploadedClientControl = {
  __typename?: 'DataMatcherUploadedClientControl';
  id: FieldWrapper<Scalars['ID']>;
  title: FieldWrapper<Scalars['String']>;
  description?: Maybe<FieldWrapper<Scalars['String']>>;
  objectives?: Maybe<FieldWrapper<Scalars['String']>>;
  controlCode: FieldWrapper<Scalars['String']>;
  controlType?: Maybe<FieldWrapper<Scalars['String']>>;
  controlOwner?: Maybe<FieldWrapper<Scalars['String']>>;
  controlFrequency?: Maybe<FieldWrapper<Scalars['String']>>;
  controlQualityScore?: Maybe<FieldWrapper<Scalars['String']>>;
};

export type IDataMatchingAcinControlPage = {
  __typename?: 'DataMatchingAcinControlPage';
  sortInfo: FieldWrapper<ISortInfo>;
  totalResults: FieldWrapper<Scalars['Int']>;
  nodes: Array<FieldWrapper<IDataMatcherAcinControlSuggestion>>;
  availableFilters: FieldWrapper<IFilters>;
};

export type IDataMatchingAcinControlPageInput = {
  dataMatcherClientControlId: Scalars['ID'];
  dataMatcherId: Scalars['ID'];
  sort?: Maybe<ISortInput>;
};

export type IDataMatchingControlsWorkflow = {
  __typename?: 'DataMatchingControlsWorkflow';
  controlView: FieldWrapper<IDataMatchingControlsWorkflowControlView>;
  summaryView: FieldWrapper<IDataMatchingControlsWorkflowSummaryView>;
};


export type IDataMatchingControlsWorkflowControlViewArgs = {
  sort?: Maybe<ISortInput>;
};


export type IDataMatchingControlsWorkflowSummaryViewArgs = {
  sort?: Maybe<ISortInput>;
};

export type IDataMatchingControlsWorkflowControlView = {
  __typename?: 'DataMatchingControlsWorkflowControlView';
  sortInfo: FieldWrapper<ISortInfo>;
  controls: FieldWrapper<IDataMatchingControlsWorkflowControlsPage>;
  matchedMissingAcinControls?: Maybe<Array<Maybe<FieldWrapper<IDataMatcherAcinControl>>>>;
};

export type IDataMatchingControlsWorkflowControlsPage = {
  __typename?: 'DataMatchingControlsWorkflowControlsPage';
  totalResults: FieldWrapper<Scalars['Int']>;
  nodes: Array<FieldWrapper<IDataMatcherClientControl>>;
};

export type IDataMatchingControlsWorkflowSummaryView = {
  __typename?: 'DataMatchingControlsWorkflowSummaryView';
  sortInfo: FieldWrapper<ISortInfo>;
  rows: Array<FieldWrapper<IDataMatchingControlsWorkflowSummaryViewRow>>;
};

export type IDataMatchingControlsWorkflowSummaryViewRow = IDataMatchingControlsWorkflowSummaryViewRowOneToOne | IDataMatchingControlsWorkflowSummaryViewRowManyToOne | IDataMatchingControlsWorkflowSummaryViewRowManyToMany;

export type IDataMatchingControlsWorkflowSummaryViewRowManyToMany = {
  __typename?: 'DataMatchingControlsWorkflowSummaryViewRowManyToMany';
  groupName: FieldWrapper<Scalars['String']>;
  items: Array<FieldWrapper<IDataMatcherClientControl>>;
  relationshipCount?: Maybe<FieldWrapper<IDataMatchingRelationshipCount>>;
};

export type IDataMatchingControlsWorkflowSummaryViewRowManyToOne = {
  __typename?: 'DataMatchingControlsWorkflowSummaryViewRowManyToOne';
  group: FieldWrapper<IAcinControl>;
  items: Array<FieldWrapper<IDataMatcherClientControl>>;
  relationshipCount?: Maybe<FieldWrapper<IDataMatchingRelationshipCount>>;
};

export type IDataMatchingControlsWorkflowSummaryViewRowOneToOne = {
  __typename?: 'DataMatchingControlsWorkflowSummaryViewRowOneToOne';
  item: FieldWrapper<IDataMatcherControl>;
};

export type IDataMatchingFile = {
  __typename?: 'DataMatchingFile';
  id: FieldWrapper<Scalars['ID']>;
  name: FieldWrapper<Scalars['String']>;
  status: FieldWrapper<IFileUploadStatus>;
  getIssues?: Maybe<FieldWrapper<IDataTable>>;
  createdAt: FieldWrapper<Scalars['String']>;
  updatedAt: FieldWrapper<Scalars['String']>;
};

export enum IDataMatchingMatchStatus {
  Unmatched = 'UNMATCHED',
  Matched = 'MATCHED',
  Rejected = 'REJECTED',
  Approved = 'APPROVED'
}

export type IDataMatchingRelationshipCount = {
  __typename?: 'DataMatchingRelationshipCount';
  to: FieldWrapper<Scalars['Int']>;
  from: FieldWrapper<Scalars['Int']>;
};

export enum IDataMatchingStatus {
  Todo = 'TODO',
  InProgress = 'IN_PROGRESS',
  Complete = 'COMPLETE'
}

export type IDataMatchingUnassignedAcinControls = {
  __typename?: 'DataMatchingUnassignedAcinControls';
  acinControls: Array<FieldWrapper<IAcinControl>>;
};

export type IDataMatchingUnassignedAcinControlsSuggestionsInput = {
  acinControlId: Scalars['ID'];
  dataMatcherId: Scalars['ID'];
  sort?: Maybe<ISortInput>;
};

export type IDataMatchingUnassignedAcinControlsSuggestionsPage = {
  __typename?: 'DataMatchingUnassignedAcinControlsSuggestionsPage';
  sortInfo: FieldWrapper<ISortInfo>;
  totalResults: FieldWrapper<Scalars['Int']>;
  nodes: Array<FieldWrapper<IDataMatcherClientControlSuggestion>>;
};

export type IDataMatchingUploadsPage = {
  __typename?: 'DataMatchingUploadsPage';
  pageInfo: FieldWrapper<IPageInfo>;
  sortInfo: FieldWrapper<ISortInfo>;
  nodes: Array<FieldWrapper<IDataMatcher>>;
};

export type IDataMatchingUploadsPageInput = {
  pagination?: Maybe<IPaginationInput>;
  sort?: Maybe<ISortInput>;
};

export type IDataOverview = {
  __typename?: 'DataOverview';
  clientControlCount: FieldWrapper<Scalars['Int']>;
  clientRiskCount: FieldWrapper<Scalars['Int']>;
  clientBusinessLineCount: FieldWrapper<Scalars['Int']>;
  acinRiskInventoryCount: FieldWrapper<Scalars['Int']>;
  acinProcessCount: FieldWrapper<Scalars['Int']>;
};

export type IDataOverviewInput = {
  filters?: Maybe<IFilterInput>;
};

export type IDataQuality = {
  __typename?: 'DataQuality';
  generatedText?: Maybe<FieldWrapper<IDataQualityGeneratedText>>;
  prompts?: Maybe<FieldWrapper<IDataQualityPrompts>>;
  score?: Maybe<FieldWrapper<IControlQualityScore>>;
};


export type IDataQualityGeneratedTextArgs = {
  textList?: Maybe<Array<IDataQualityInputText>>;
  prompt?: Maybe<Scalars['String']>;
};


export type IDataQualityPromptsArgs = {
  promptCategory: IDataQualityPromptCategory;
};


export type IDataQualityScoreArgs = {
  textList?: Maybe<Array<IDataQualityInputText>>;
};

export type IDataQualityGeneratedText = {
  __typename?: 'DataQualityGeneratedText';
  text: FieldWrapper<Scalars['String']>;
};

export type IDataQualityInputText = {
  variant: IDataQualityInputTextVariant;
  text: Scalars['String'];
};

export enum IDataQualityInputTextVariant {
  Control = 'CONTROL',
  Policy = 'POLICY',
  Regulation = 'REGULATION'
}

export type IDataQualityJob = {
  __typename?: 'DataQualityJob';
  id: FieldWrapper<Scalars['ID']>;
  status: FieldWrapper<IDataQualityJobStatus>;
  value?: Maybe<FieldWrapper<Scalars['String']>>;
};

export enum IDataQualityJobStatus {
  Started = 'STARTED',
  Completed = 'COMPLETED',
  Failed = 'FAILED'
}

export enum IDataQualityPromptCategory {
  ControlRewriter = 'CONTROL_REWRITER',
  PolicyToControl = 'POLICY_TO_CONTROL',
  ProcedureToControl = 'PROCEDURE_TO_CONTROL',
  ProcessToControl = 'PROCESS_TO_CONTROL',
  ControlToTestingScript = 'CONTROL_TO_TESTING_SCRIPT',
  ControlToKeyControlIndicator = 'CONTROL_TO_KEY_CONTROL_INDICATOR'
}

export type IDataQualityPromptOption = {
  __typename?: 'DataQualityPromptOption';
  value: FieldWrapper<Scalars['String']>;
  label: FieldWrapper<Scalars['String']>;
};

export type IDataQualityPrompts = {
  __typename?: 'DataQualityPrompts';
  promptOptions: Array<FieldWrapper<IDataQualityPromptOption>>;
};

export type IDataTable = {
  __typename?: 'DataTable';
  headers: Array<FieldWrapper<IDataTableHeader>>;
  rows: Array<FieldWrapper<IDataTableRow>>;
};

export type IDataTableField = {
  __typename?: 'DataTableField';
  key: FieldWrapper<Scalars['String']>;
  value?: Maybe<FieldWrapper<Scalars['String']>>;
};

export type IDataTableHeader = {
  __typename?: 'DataTableHeader';
  key: FieldWrapper<Scalars['String']>;
  name: FieldWrapper<Scalars['String']>;
  sortable: FieldWrapper<Scalars['Boolean']>;
  direction?: Maybe<FieldWrapper<ISortDirection>>;
  format?: Maybe<FieldWrapper<IDataFormat>>;
};

export type IDataTableRow = {
  __typename?: 'DataTableRow';
  fields: Array<FieldWrapper<IDataTableField>>;
  meta?: Maybe<FieldWrapper<IDataTableRowMeta>>;
};

export type IDataTableRowMeta = {
  __typename?: 'DataTableRowMeta';
  raw?: Maybe<Array<FieldWrapper<IDataTableRowMetaValue>>>;
};

export type IDataTableRowMetaValue = {
  __typename?: 'DataTableRowMetaValue';
  key: FieldWrapper<Scalars['String']>;
  value: FieldWrapper<Scalars['String']>;
};

export type IDonutChart = {
  __typename?: 'DonutChart';
  colorPaletteName: FieldWrapper<IColorPaletteName>;
  values: Array<FieldWrapper<IDonutChartValue>>;
};

export type IDonutChartValue = {
  __typename?: 'DonutChartValue';
  label: FieldWrapper<Scalars['String']>;
  filterPayload?: Maybe<FieldWrapper<IFilterPayload>>;
  value: FieldWrapper<Scalars['Float']>;
  colorPalette?: Maybe<FieldWrapper<IColorPalette>>;
  type?: Maybe<FieldWrapper<IDashboardItemType>>;
};

export type IDownloadFile = {
  __typename?: 'DownloadFile';
  id: FieldWrapper<Scalars['ID']>;
  fileURL?: Maybe<FieldWrapper<Scalars['String']>>;
  status: FieldWrapper<IDownloadStatus>;
  contentType: FieldWrapper<Scalars['String']>;
};

export enum IDownloadFileType {
  Csv = 'CSV',
  Excel = 'EXCEL'
}

export enum IDownloadStatus {
  Pending = 'PENDING',
  Complete = 'COMPLETE',
  Failed = 'FAILED'
}

export type IDrillDownInfo = {
  __typename?: 'DrillDownInfo';
  id?: Maybe<FieldWrapper<Scalars['ID']>>;
  configuration?: Maybe<Array<FieldWrapper<IFilterPayloadInputGroup>>>;
  filters?: Maybe<Array<FieldWrapper<IFilterPayloadInputGroup>>>;
  label?: Maybe<FieldWrapper<Scalars['String']>>;
};

export type IEmailNotificationCategory = {
  __typename?: 'EmailNotificationCategory';
  name: FieldWrapper<Scalars['String']>;
  code: FieldWrapper<Scalars['String']>;
  isEnabled: FieldWrapper<Scalars['Boolean']>;
};

export type IEmailNotificationSettings = {
  __typename?: 'EmailNotificationSettings';
  notificationCategories: Array<FieldWrapper<IEmailNotificationCategory>>;
};

export type IEntityAssigned = {
  __typename?: 'EntityAssigned';
  id: FieldWrapper<Scalars['String']>;
  matchers: Array<FieldWrapper<IConnectionUser>>;
  approvers: Array<FieldWrapper<IConnectionUser>>;
  permissions: Array<FieldWrapper<Scalars['String']>>;
};

export type IEntityAssigneesInput = {
  accountCode: Scalars['String'];
  jobId: Scalars['String'];
  type: IEntityAssigneesType;
  ids?: Maybe<Array<Scalars['String']>>;
  matchersIds: Array<Scalars['String']>;
  approversIds: Array<Scalars['String']>;
};

export type IEntityAssigneesResponse = {
  __typename?: 'EntityAssigneesResponse';
  type: FieldWrapper<IEntityAssigneesType>;
  entity: Array<FieldWrapper<IEntityAssigned>>;
};

export enum IEntityAssigneesType {
  Control = 'CONTROL',
  Cluster = 'CLUSTER'
}

export type IExample = {
  __typename?: 'Example';
  id: FieldWrapper<Scalars['ID']>;
  title: FieldWrapper<Scalars['String']>;
  content: FieldWrapper<Scalars['String']>;
};

export type IExampleInput = {
  title: Scalars['String'];
  content: Scalars['String'];
};

export type IExamplePaginationItem = {
  __typename?: 'ExamplePaginationItem';
  id: FieldWrapper<Scalars['ID']>;
  title: FieldWrapper<Scalars['String']>;
  description: FieldWrapper<Scalars['String']>;
  value: FieldWrapper<Scalars['Int']>;
};

export type IExportStatusReportInput = {
  type: IDownloadFileType;
};

export type IExportTemplateQueryInput = {
  type: IDownloadFileType;
};

export type IFeedInsight = {
  __typename?: 'FeedInsight';
  id: FieldWrapper<Scalars['ID']>;
  type: FieldWrapper<IInsightType>;
  criteria: FieldWrapper<Scalars['String']>;
  createdAt: FieldWrapper<Scalars['String']>;
  reason: Array<FieldWrapper<IInsightReason>>;
  description: FieldWrapper<Scalars['String']>;
  metrics: Array<FieldWrapper<IInsightMetric>>;
};

export type IFeedInsightsPage = {
  __typename?: 'FeedInsightsPage';
  nodes: Array<FieldWrapper<IFeedInsight>>;
};

export enum IFileUploadStatus {
  Uploaded = 'UPLOADED',
  Processing = 'PROCESSING',
  Completed = 'COMPLETED',
  UnsupportedFile = 'UNSUPPORTED_FILE',
  Error = 'ERROR',
  CompletedWithWarnings = 'COMPLETED_WITH_WARNINGS'
}

export type IFilterCheckboxListInputType = {
  __typename?: 'FilterCheckboxListInputType';
  inputType: FieldWrapper<IFilterGroupInputType>;
  options: Array<FieldWrapper<IFilterItem>>;
};

export type IFilterDropdownInputType = {
  __typename?: 'FilterDropdownInputType';
  inputType: FieldWrapper<IFilterGroupInputType>;
  options: Array<FieldWrapper<IFilterItem>>;
  placeholder?: Maybe<FieldWrapper<Scalars['String']>>;
};

export type IFilterGroup = {
  __typename?: 'FilterGroup';
  key: FieldWrapper<Scalars['String']>;
  label?: Maybe<FieldWrapper<Scalars['String']>>;
  filterInput: FieldWrapper<IFilterInputTypes>;
};

export enum IFilterGroupInputType {
  Dropdown = 'DROPDOWN',
  Tags = 'TAGS',
  Checkbox = 'CHECKBOX',
  Range = 'RANGE'
}

export type IFilterGroupV2 = {
  __typename?: 'FilterGroupV2';
  label?: Maybe<FieldWrapper<Scalars['String']>>;
  availableFilters: Array<FieldWrapper<IFilterPayloadValue>>;
};

export type IFilterInput = {
  groups?: Maybe<Array<IFilterInputGroup>>;
};

export type IFilterInputGroup = {
  groupKey: Scalars['String'];
  filterValues: Array<IFilterInputValue>;
};

export type IFilterInputTypes = IFilterDropdownInputType | IFilterTagsInputType | IFilterCheckboxListInputType | IFilterRangeInputType;

export type IFilterInputValue = {
  value: Scalars['String'];
  label?: Maybe<Scalars['String']>;
  sublabel?: Maybe<Scalars['String']>;
};

export type IFilterItem = {
  __typename?: 'FilterItem';
  key: FieldWrapper<Scalars['String']>;
  label: FieldWrapper<Scalars['String']>;
  code?: Maybe<FieldWrapper<Scalars['String']>>;
};

export type IFilterListLevelVariant = {
  __typename?: 'FilterListLevelVariant';
  searchPlaceholder?: Maybe<FieldWrapper<Scalars['String']>>;
  appliedFilters: Array<FieldWrapper<IFilterPayloadListValue>>;
  appliedFiltersCount: FieldWrapper<Scalars['Int']>;
};

export type IFilterListVariant = {
  __typename?: 'FilterListVariant';
  searchPlaceholder?: Maybe<FieldWrapper<Scalars['String']>>;
  filterGroups: Array<FieldWrapper<IFilterGroupV2>>;
  appliedFilters: Array<FieldWrapper<IFilterPayloadValue>>;
  appliedFiltersCount: FieldWrapper<Scalars['Int']>;
};

export type IFilterPayload = {
  __typename?: 'FilterPayload';
  groups?: Maybe<Array<FieldWrapper<IFilterPayloadInputGroup>>>;
};

export type IFilterPayloadInputGroup = {
  __typename?: 'FilterPayloadInputGroup';
  groupKey: FieldWrapper<Scalars['String']>;
  filterValues: Array<FieldWrapper<IFilterPayloadValue>>;
  label?: Maybe<FieldWrapper<Scalars['String']>>;
};

export type IFilterPayloadListValue = {
  __typename?: 'FilterPayloadListValue';
  value: FieldWrapper<Scalars['String']>;
  code: FieldWrapper<Scalars['String']>;
  label?: Maybe<FieldWrapper<Scalars['String']>>;
  sublabel?: Maybe<FieldWrapper<Scalars['String']>>;
  parentIds?: Maybe<Array<FieldWrapper<Scalars['String']>>>;
  hasChildren?: Maybe<FieldWrapper<Scalars['Boolean']>>;
  lastLevelFilterCount?: Maybe<FieldWrapper<Scalars['Int']>>;
  siblingCounts?: Maybe<FieldWrapper<Scalars['Int']>>;
};

export type IFilterPayloadValue = {
  __typename?: 'FilterPayloadValue';
  value: FieldWrapper<Scalars['String']>;
  label?: Maybe<FieldWrapper<Scalars['String']>>;
  sublabel?: Maybe<FieldWrapper<Scalars['String']>>;
};

export type IFilterRange = {
  __typename?: 'FilterRange';
  min: FieldWrapper<Scalars['Int']>;
  max: FieldWrapper<Scalars['Int']>;
};

export type IFilterRangeInputType = {
  __typename?: 'FilterRangeInputType';
  inputType: FieldWrapper<IFilterGroupInputType>;
  tagLabel?: Maybe<FieldWrapper<Scalars['String']>>;
  tagUnit?: Maybe<FieldWrapper<Scalars['String']>>;
  range: FieldWrapper<IFilterRange>;
  description: FieldWrapper<Scalars['String']>;
};

export type IFilterRangeVariant = {
  __typename?: 'FilterRangeVariant';
  range: FieldWrapper<IFilterRange>;
  activeRange?: Maybe<FieldWrapper<IFilterRange>>;
};

export type IFilterResponse = {
  __typename?: 'FilterResponse';
  allFilters: Array<FieldWrapper<IFilterV2>>;
  exposedFilterIds: Array<FieldWrapper<Scalars['ID']>>;
};

export type IFilterTagsInputType = {
  __typename?: 'FilterTagsInputType';
  inputType: FieldWrapper<IFilterGroupInputType>;
  options: Array<FieldWrapper<IFilterItem>>;
};

export enum IFilterType {
  List = 'LIST',
  RadioList = 'RADIO_LIST',
  GroupList = 'GROUP_LIST',
  Range = 'RANGE',
  ListLevel = 'LIST_LEVEL'
}

export type IFilterV2 = {
  __typename?: 'FilterV2';
  id: FieldWrapper<Scalars['ID']>;
  code: FieldWrapper<Scalars['String']>;
  type: FieldWrapper<IFilterType>;
  label: FieldWrapper<Scalars['String']>;
  description?: Maybe<FieldWrapper<Scalars['String']>>;
  info?: Maybe<FieldWrapper<Scalars['String']>>;
  attributes: FieldWrapper<IFilterVariant>;
};

export type IFilterVariant = IFilterListVariant | IFilterRangeVariant | IFilterListLevelVariant;

export type IFilters = {
  __typename?: 'Filters';
  groups: Array<FieldWrapper<IFilterGroup>>;
};

export type IGetRiskDataInput = {
  ids: Array<Scalars['String']>;
  sort?: Maybe<ISortInput>;
};

export type IGlobalSearchResult = {
  __typename?: 'GlobalSearchResult';
  id: FieldWrapper<Scalars['String']>;
  text: FieldWrapper<Scalars['String']>;
  assetType: FieldWrapper<IGlobalSearchResultAssetType>;
};

export enum IGlobalSearchResultAssetType {
  ClientControl = 'CLIENT_CONTROL',
  AcinControl = 'ACIN_CONTROL',
  ClientRisk = 'CLIENT_RISK',
  AcinRisk = 'ACIN_RISK',
  RegAlert = 'REG_ALERT',
  NetworkUpdate = 'NETWORK_UPDATE',
  NetworkScenario = 'NETWORK_SCENARIO',
  Report = 'REPORT',
  ReportBuilder = 'REPORT_BUILDER'
}

export type IHorizontalDumbbellPlotChart = {
  __typename?: 'HorizontalDumbbellPlotChart';
  colorPaletteName: FieldWrapper<IColorPaletteName>;
  mean?: Maybe<FieldWrapper<Scalars['Float']>>;
  rangeKey: FieldWrapper<IChartKey>;
  valueKey: FieldWrapper<IChartKey>;
  values: Array<FieldWrapper<IHorizontalDumbbellPlotChartValue>>;
};

export type IHorizontalDumbbellPlotChartRange = {
  __typename?: 'HorizontalDumbbellPlotChartRange';
  rangeStart: FieldWrapper<Scalars['Float']>;
  rangeEnd: FieldWrapper<Scalars['Float']>;
};

export type IHorizontalDumbbellPlotChartValue = {
  __typename?: 'HorizontalDumbbellPlotChartValue';
  label: FieldWrapper<Scalars['String']>;
  value: FieldWrapper<Scalars['Float']>;
  range: FieldWrapper<IHorizontalDumbbellPlotChartRange>;
};

export type IInsightMetric = {
  __typename?: 'InsightMetric';
  type: FieldWrapper<IInsightMetricType>;
  values: Array<FieldWrapper<IInsightMetricValue>>;
};

export enum IInsightMetricType {
  ETrading = 'E_TRADING',
  CrossAsset = 'CROSS_ASSET',
  AssetClass = 'ASSET_CLASS',
  Continuity = 'CONTINUITY',
  ControlImpact = 'CONTROL_IMPACT',
  Rating = 'RATING',
  All = 'ALL'
}

export type IInsightMetricValue = {
  __typename?: 'InsightMetricValue';
  id: FieldWrapper<Scalars['ID']>;
  title: FieldWrapper<Scalars['String']>;
  value?: Maybe<FieldWrapper<Scalars['String']>>;
};

export type IInsightReason = {
  __typename?: 'InsightReason';
  id: FieldWrapper<Scalars['ID']>;
  title: FieldWrapper<Scalars['String']>;
};

export enum IInsightType {
  RatingIncrease = 'RATING_INCREASE',
  RatingDecrease = 'RATING_DECREASE',
  NewControl = 'NEW_CONTROL',
  NewRisk = 'NEW_RISK'
}

export enum IInspectorIdType {
  Control = 'Control'
}

export type IInspectorInput = {
  id: Scalars['ID'];
  idType: IInspectorIdType;
};

export type IInspectorPage = {
  __typename?: 'InspectorPage';
  content?: Maybe<FieldWrapper<IInspectorPageView>>;
};

export type IInspectorPageView = IControlInspectorView;

export type IItemsPerPageOption = {
  __typename?: 'ItemsPerPageOption';
  value: FieldWrapper<Scalars['Int']>;
  label: FieldWrapper<Scalars['String']>;
};

export type IKeyStat = {
  __typename?: 'KeyStat';
  value?: Maybe<FieldWrapper<Scalars['String']>>;
  label: FieldWrapper<Scalars['String']>;
  isPercentage?: Maybe<FieldWrapper<Scalars['Boolean']>>;
};

export type IKeyword = {
  __typename?: 'Keyword';
  id: FieldWrapper<Scalars['ID']>;
  code: FieldWrapper<Scalars['String']>;
  name: FieldWrapper<Scalars['String']>;
  count: FieldWrapper<Scalars['Int']>;
  items: Array<FieldWrapper<IKeywordItem>>;
};

export type IKeywordItem = {
  __typename?: 'KeywordItem';
  id: FieldWrapper<Scalars['ID']>;
  name: FieldWrapper<Scalars['String']>;
};

export type IMatchAcinControlInput = {
  dataMatcherId: Scalars['ID'];
  dataMatcherClientControlId: Scalars['ID'];
  matches: Array<IMatchAcinControlMatchInput>;
};

export type IMatchAcinControlMatchInput = {
  matchId?: Maybe<Scalars['ID']>;
  reasonCode: Scalars['String'];
  acinControlId?: Maybe<Scalars['ID']>;
  internalComment?: Maybe<Scalars['String']>;
  externalComment?: Maybe<Scalars['String']>;
};

export type IMatchClientControlMatchInput = {
  reasonCode: Scalars['String'];
  dataMatcherUploadedClientControlId?: Maybe<Scalars['ID']>;
  internalComment?: Maybe<Scalars['String']>;
  externalComment?: Maybe<Scalars['String']>;
};

export type IMatchUnassignedClientControlInput = {
  dataMatcherId: Scalars['ID'];
  acinControlId: Scalars['ID'];
  matches: Array<IMatchClientControlMatchInput>;
};

export type IMatchingControl = {
  __typename?: 'MatchingControl';
  id: FieldWrapper<Scalars['String']>;
  code: FieldWrapper<Scalars['String']>;
  name: FieldWrapper<Scalars['String']>;
  description: FieldWrapper<Scalars['String']>;
  controlType?: Maybe<FieldWrapper<Scalars['String']>>;
  frequency?: Maybe<FieldWrapper<Scalars['String']>>;
  automation?: Maybe<FieldWrapper<Scalars['String']>>;
  riaDescription?: Maybe<FieldWrapper<IMatchingRiaDescription>>;
  risks: Array<FieldWrapper<IMatchingRisk>>;
  indices: Array<FieldWrapper<IMatchingIndices>>;
};

export type IMatchingControlsInput = {
  filters?: Maybe<IFilterInput>;
  pagination?: Maybe<IPaginationInput>;
};

export type IMatchingControlsResponse = {
  __typename?: 'MatchingControlsResponse';
  controls: Array<FieldWrapper<IMatchingControl>>;
  pagination?: Maybe<FieldWrapper<IPageInfo>>;
};

export type IMatchingIndices = {
  __typename?: 'MatchingIndices';
  code: FieldWrapper<Scalars['String']>;
  name: FieldWrapper<Scalars['String']>;
};

export type IMatchingJob = {
  __typename?: 'MatchingJob';
  id: FieldWrapper<Scalars['String']>;
  name: FieldWrapper<Scalars['String']>;
  stage: FieldWrapper<IAdpFileStage>;
  status: FieldWrapper<IAdpFileStatus>;
  numberOfControls: FieldWrapper<Scalars['Int']>;
  numberOfRisks: FieldWrapper<Scalars['Int']>;
  fileUploaded?: Maybe<FieldWrapper<Scalars['String']>>;
  permissions?: Maybe<Array<Maybe<FieldWrapper<Scalars['String']>>>>;
};

export type IMatchingJobApproval = {
  matchedIds: Array<Scalars['ID']>;
  quality: IUpdateProposedQuality;
};

export type IMatchingJobCluster = {
  __typename?: 'MatchingJobCluster';
  id: FieldWrapper<Scalars['ID']>;
  name: FieldWrapper<Scalars['String']>;
  status: FieldWrapper<IMatchingJobClusterStatus>;
  numberOfControls: FieldWrapper<Scalars['Int']>;
  matchers: Array<FieldWrapper<IConnectionUser>>;
  approvers: Array<FieldWrapper<IConnectionUser>>;
  permissions?: Maybe<Array<Maybe<FieldWrapper<Scalars['String']>>>>;
};

export enum IMatchingJobClusterStatus {
  ToConnect = 'TO_CONNECT',
  InProgress = 'IN_PROGRESS',
  ReadyToSubmit = 'READY_TO_SUBMIT',
  ReadyForApproval = 'READY_FOR_APPROVAL',
  Approved = 'APPROVED',
  Completed = 'COMPLETED'
}

export type IMatchingJobClustersFiltersInput = {
  matchingJobId: Scalars['ID'];
  accountCode: Scalars['String'];
};

export type IMatchingJobClustersInput = {
  accountCode: Scalars['String'];
  matchingJobId: Scalars['String'];
  pagination?: Maybe<IPaginationInput>;
  filters?: Maybe<IFilterInput>;
};

export type IMatchingJobClustersResponse = {
  __typename?: 'MatchingJobClustersResponse';
  clusters: Array<FieldWrapper<IMatchingJobCluster>>;
  pagination?: Maybe<FieldWrapper<IPageInfo>>;
};

export type IMatchingJobControl = {
  __typename?: 'MatchingJobControl';
  id: FieldWrapper<Scalars['String']>;
  code: FieldWrapper<Scalars['String']>;
  name: FieldWrapper<Scalars['String']>;
  description: FieldWrapper<Scalars['String']>;
  status: FieldWrapper<IMatchingJobControlStatus>;
  suggestedQualityScore?: Maybe<FieldWrapper<Scalars['Float']>>;
  quality?: Maybe<FieldWrapper<IMatchingJobQualityLevel>>;
  controlType?: Maybe<FieldWrapper<Scalars['String']>>;
  frequency?: Maybe<FieldWrapper<Scalars['String']>>;
  automation?: Maybe<FieldWrapper<Scalars['String']>>;
  risks: Array<FieldWrapper<IMatchingRisk>>;
  indices: Array<FieldWrapper<IMatchingIndices>>;
  suggestedMatches?: Maybe<Array<FieldWrapper<ISuggestedMatch>>>;
  proposedMatches?: Maybe<Array<FieldWrapper<IMatchingControl>>>;
  matched?: Maybe<Array<FieldWrapper<IMatchingControl>>>;
  proposedMatchesBy?: Maybe<FieldWrapper<IUser>>;
  matchesBy?: Maybe<FieldWrapper<IUser>>;
  matchedAt?: Maybe<FieldWrapper<Scalars['String']>>;
  riaDescription?: Maybe<FieldWrapper<IMatchingRiaDescription>>;
  matcher?: Maybe<FieldWrapper<IConnectionUser>>;
  approver?: Maybe<FieldWrapper<IConnectionUser>>;
  permissions?: Maybe<Array<Maybe<FieldWrapper<Scalars['String']>>>>;
  action?: Maybe<FieldWrapper<IMatchingJobControlAction>>;
  unclearQualityDescriptorId?: Maybe<FieldWrapper<Scalars['String']>>;
};

export enum IMatchingJobControlAction {
  Approved = 'APPROVED',
  AutoMatched = 'AUTO_MATCHED',
  Proposed = 'PROPOSED',
  Inherited = 'INHERITED'
}

export type IMatchingJobControlMetrics = {
  timeToProgress?: Maybe<Scalars['Int']>;
};

export enum IMatchingJobControlStatus {
  ToConnect = 'TO_CONNECT',
  Skipped = 'SKIPPED',
  PreConnected = 'PRE_CONNECTED',
  ReadyForApproval = 'READY_FOR_APPROVAL',
  Approved = 'APPROVED',
  Completed = 'COMPLETED'
}

export type IMatchingJobControlsInput = {
  accountCode: Scalars['String'];
  matchingJobId: Scalars['String'];
  clusterId?: Maybe<Scalars['String']>;
  pagination?: Maybe<IPaginationInput>;
  filters?: Maybe<IFilterInput>;
};

export type IMatchingJobProposal = {
  matchedIds?: Maybe<Array<Scalars['ID']>>;
  quality?: Maybe<IUpdateProposedQuality>;
};

export enum IMatchingJobQualityLevel {
  Clear = 'CLEAR',
  Unclear = 'UNCLEAR'
}

export type IMatchingJobsControlResponse = {
  __typename?: 'MatchingJobsControlResponse';
  controls: Array<FieldWrapper<IMatchingJobControl>>;
  pagination?: Maybe<FieldWrapper<IPageInfo>>;
};

export type IMatchingJobsInput = {
  accountCode: Scalars['String'];
  pagination?: Maybe<IPaginationInput>;
  filters?: Maybe<IFilterInput>;
};

export type IMatchingJobsResponse = {
  __typename?: 'MatchingJobsResponse';
  matchingJobs: Array<FieldWrapper<IMatchingJob>>;
  pagination?: Maybe<FieldWrapper<IPageInfo>>;
};

export type IMatchingRiaDescription = {
  __typename?: 'MatchingRiaDescription';
  review: FieldWrapper<Scalars['String']>;
  identify: FieldWrapper<Scalars['String']>;
  action: FieldWrapper<Scalars['String']>;
};

export type IMatchingRisk = {
  __typename?: 'MatchingRisk';
  code: FieldWrapper<Scalars['String']>;
  name: FieldWrapper<Scalars['String']>;
};

export type IMockedPaginationResults = {
  __typename?: 'MockedPaginationResults';
  pagination: FieldWrapper<IPageInfo>;
  nodes: Array<FieldWrapper<IExamplePaginationItem>>;
};

export type IMutation = {
  __typename?: 'Mutation';
  setUITheme?: Maybe<FieldWrapper<IUserPreferences>>;
  updateEmailNotificationSettings: FieldWrapper<IEmailNotificationSettings>;
  generateCalibrateDownload: FieldWrapper<ICalibrateDownloadFile>;
  markCalibrateDownloadAsRead: FieldWrapper<ICalibrateDownloadFile>;
  deleteCalibrateDownload: FieldWrapper<ICalibrateDownloadFile>;
  createDataMatcher: FieldWrapper<IDataMatcher>;
  matchAcinControlToClientControl: FieldWrapper<IDataMatcher>;
  matchUnassignedClientControlToAcinControl: FieldWrapper<IDataMatcher>;
  approveRejectAcinControlToClientControl: FieldWrapper<IDataMatcher>;
  approveRejectMissingAcinControl: FieldWrapper<IDataMatcher>;
  submitDataMatcher: FieldWrapper<IDataMatcher>;
  deleteDataMatcher: FieldWrapper<IDataMatcher>;
  inheritUnmatchedAcinControlReasonCodes: FieldWrapper<Scalars['ID']>;
  publish: FieldWrapper<IPublishResponse>;
  bulkApprove: FieldWrapper<Scalars['Boolean']>;
  submitControlsForApproval: FieldWrapper<ISubmitControlsForApprovalResponse>;
  submitClustersForApproval: FieldWrapper<ISubmitClustersForApprovalResponse>;
  updateMatchingJobControl?: Maybe<FieldWrapper<IMatchingJobControl>>;
  updateUnmatchedIndexControl?: Maybe<FieldWrapper<IUnmatchedIndexControl>>;
  updateEntityAssignees: FieldWrapper<IEntityAssigneesResponse>;
  toggleTemplateQueryFavourite?: Maybe<FieldWrapper<ITemplateQuery>>;
  exportTemplateQueryById: FieldWrapper<IDownloadFile>;
  exportCompletenessReportById: FieldWrapper<IDownloadFile>;
  createReport: FieldWrapper<ITemplateQuery>;
  updateReport: FieldWrapper<ITemplateQuery>;
  deleteReport: FieldWrapper<ITemplateQuery>;
  exportStatusReportById: FieldWrapper<IDownloadFile>;
  getDataQuality: FieldWrapper<IDataQuality>;
  createDataQualityJob: FieldWrapper<IDataQualityJob>;
  toggleRiskIntelligenceFavourite: FieldWrapper<IRiskIntelligence>;
  toggleRiskIntelligenceTableItemFavourite: FieldWrapper<IRiskIntelligenceTableItem>;
  createExample: FieldWrapper<IExample>;
  toggleReportFavourite: FieldWrapper<IReport>;
  createConfiguratorReport: FieldWrapper<IConfiguratorReportResult>;
  updateConfiguratorReport: FieldWrapper<IConfiguratorReportResult>;
  deleteConfiguratorReport: FieldWrapper<IConfiguratorReportResult>;
};


export type IMutationSetUiThemeArgs = {
  userId: Scalars['String'];
  theme: IUiTheme;
};


export type IMutationUpdateEmailNotificationSettingsArgs = {
  input: IUpdateEmailNotificationSettingsInput;
};


export type IMutationGenerateCalibrateDownloadArgs = {
  input: ICalibrateReportInput;
};


export type IMutationMarkCalibrateDownloadAsReadArgs = {
  id: Scalars['ID'];
};


export type IMutationDeleteCalibrateDownloadArgs = {
  id: Scalars['ID'];
};


export type IMutationCreateDataMatcherArgs = {
  input: ICreateDataMatcherInput;
};


export type IMutationMatchAcinControlToClientControlArgs = {
  input: IMatchAcinControlInput;
  filterInput?: Maybe<IFilterInput>;
};


export type IMutationMatchUnassignedClientControlToAcinControlArgs = {
  input: IMatchUnassignedClientControlInput;
};


export type IMutationApproveRejectAcinControlToClientControlArgs = {
  input: IApproveRejectMatchAcinControlInput;
};


export type IMutationApproveRejectMissingAcinControlArgs = {
  input: IApproveRejectMissingAcinControlInput;
};


export type IMutationSubmitDataMatcherArgs = {
  dataMatcherId: Scalars['ID'];
};


export type IMutationDeleteDataMatcherArgs = {
  id: Scalars['ID'];
};


export type IMutationInheritUnmatchedAcinControlReasonCodesArgs = {
  dataMatcherId: Scalars['ID'];
  unassignedAcinControlIds: Array<Maybe<Scalars['ID']>>;
};


export type IMutationPublishArgs = {
  input: IPublishInput;
};


export type IMutationBulkApproveArgs = {
  input: IBulkApproveInput;
};


export type IMutationSubmitControlsForApprovalArgs = {
  input: ISubmitControlsForApprovalInput;
};


export type IMutationSubmitClustersForApprovalArgs = {
  input: ISubmitClustersForApprovalInput;
};


export type IMutationUpdateMatchingJobControlArgs = {
  input?: Maybe<IUpdateMatchingJobControlInput>;
};


export type IMutationUpdateUnmatchedIndexControlArgs = {
  input: IUpdateUnmatchedIndexControlInput;
};


export type IMutationUpdateEntityAssigneesArgs = {
  input: IEntityAssigneesInput;
};


export type IMutationToggleTemplateQueryFavouriteArgs = {
  id: Scalars['ID'];
  favourite: Scalars['Boolean'];
};


export type IMutationExportTemplateQueryByIdArgs = {
  id: Scalars['ID'];
  export: IExportTemplateQueryInput;
  filters?: Maybe<IFilterInput>;
};


export type IMutationExportCompletenessReportByIdArgs = {
  configuration: IFilterInput;
  export: IExportTemplateQueryInput;
  filters?: Maybe<IFilterInput>;
  reportId?: Maybe<Scalars['String']>;
};


export type IMutationCreateReportArgs = {
  baseReportId: Scalars['ID'];
  report: ICreateReportInput;
};


export type IMutationUpdateReportArgs = {
  id: Scalars['ID'];
  report: IUpdateReportInput;
};


export type IMutationDeleteReportArgs = {
  id: Scalars['ID'];
};


export type IMutationExportStatusReportByIdArgs = {
  id: Scalars['ID'];
  export: IExportStatusReportInput;
};


export type IMutationCreateDataQualityJobArgs = {
  textList: Array<IDataQualityInputText>;
  prompt: Scalars['String'];
};


export type IMutationToggleRiskIntelligenceFavouriteArgs = {
  id: Scalars['ID'];
  favourite: Scalars['Boolean'];
};


export type IMutationToggleRiskIntelligenceTableItemFavouriteArgs = {
  id: Scalars['ID'];
  favourite: Scalars['Boolean'];
};


export type IMutationCreateExampleArgs = {
  input: IExampleInput;
};


export type IMutationToggleReportFavouriteArgs = {
  id: Scalars['ID'];
  favourite: Scalars['Boolean'];
};


export type IMutationCreateConfiguratorReportArgs = {
  report: IConfiguratorReportInput;
};


export type IMutationUpdateConfiguratorReportArgs = {
  id: Scalars['ID'];
  report: IUpdateConfiguratorReportInput;
};


export type IMutationDeleteConfiguratorReportArgs = {
  id: Scalars['ID'];
};

export type IMyReports = {
  __typename?: 'MyReports';
  reports: Array<FieldWrapper<IReport>>;
  pagination?: Maybe<FieldWrapper<IPageInfo>>;
  sorting?: Maybe<FieldWrapper<ISortField>>;
  unfilteredTotal?: Maybe<FieldWrapper<Scalars['Int']>>;
};

export type IMyReportsInput = {
  filters?: Maybe<IFilterInput>;
  sorting?: Maybe<ISortInput>;
  pagination?: Maybe<IPaginationInput>;
  searchTerm?: Maybe<Scalars['String']>;
  pageCode: IPageCode;
};

export type INetworkAlignmentScoreProgress = {
  __typename?: 'NetworkAlignmentScoreProgress';
  name: FieldWrapper<Scalars['String']>;
  colorPaletteName: FieldWrapper<IColorPaletteName>;
  value?: Maybe<FieldWrapper<Scalars['Float']>>;
  min: FieldWrapper<Scalars['Float']>;
  max: FieldWrapper<Scalars['Float']>;
  formatter: FieldWrapper<IChart_Value_Format>;
  extendedMin: FieldWrapper<Scalars['Boolean']>;
  extendedMax: FieldWrapper<Scalars['Boolean']>;
  maxAxisTicks?: Maybe<FieldWrapper<Scalars['Int']>>;
};

export type INetworkSignalScore = {
  __typename?: 'NetworkSignalScore';
  score: FieldWrapper<Scalars['Float']>;
  total?: Maybe<FieldWrapper<Scalars['Int']>>;
  highlight: FieldWrapper<Scalars['Boolean']>;
};

export enum IPageCode {
  ClientControls = 'CLIENT_CONTROLS',
  ClientControlsQuality = 'CLIENT_CONTROLS_QUALITY',
  AcinControls = 'ACIN_CONTROLS',
  JointControls = 'JOINT_CONTROLS'
}

export type IPageInfo = {
  __typename?: 'PageInfo';
  page: FieldWrapper<Scalars['Int']>;
  pageSize: FieldWrapper<Scalars['Int']>;
  totalResults: FieldWrapper<Scalars['Int']>;
  totalPages: FieldWrapper<Scalars['Int']>;
  hasNextPage: FieldWrapper<Scalars['Boolean']>;
  range?: Maybe<FieldWrapper<Scalars['String']>>;
  subPage?: Maybe<FieldWrapper<Scalars['Int']>>;
  subPageSize?: Maybe<FieldWrapper<Scalars['Int']>>;
  hasNextSubPage?: Maybe<FieldWrapper<Scalars['Boolean']>>;
  totalSubPageResults?: Maybe<FieldWrapper<Scalars['Int']>>;
};

export type IPaginationInput = {
  page: Scalars['Int'];
  pageSize?: Maybe<Scalars['Int']>;
  subPage?: Maybe<Scalars['Int']>;
  subPageSize?: Maybe<Scalars['Int']>;
};

export type IProduct = {
  __typename?: 'Product';
  id: FieldWrapper<Scalars['ID']>;
  label: FieldWrapper<Scalars['String']>;
  type: FieldWrapper<IProductType>;
};

export type IProductInput = {
  type?: Maybe<IProductType>;
};

export type IProductPage = {
  __typename?: 'ProductPage';
  nodes: Array<FieldWrapper<IProduct>>;
};

export enum IProductType {
  Asset = 'ASSET',
  Trading = 'TRADING',
  Instrument = 'INSTRUMENT',
  Settlement = 'SETTLEMENT'
}

export type IPublishInput = {
  accountCode: Scalars['String'];
  matchingJobId: Scalars['String'];
};

export type IPublishResponse = {
  __typename?: 'PublishResponse';
  started: FieldWrapper<Scalars['Boolean']>;
};

export type IQuery = {
  __typename?: 'Query';
  getAccounts: Array<FieldWrapper<IAccount>>;
  searchAccounts: FieldWrapper<ISearchAccountsPage>;
  getUsers: Array<FieldWrapper<IUser>>;
  getAccount: FieldWrapper<IAccount>;
  getUser: FieldWrapper<IUser>;
  getRoles?: Maybe<Array<FieldWrapper<IRole>>>;
  me: FieldWrapper<IUser>;
  getEmailNotificationSettings: FieldWrapper<IEmailNotificationSettings>;
  getUserAuthorization: FieldWrapper<IUserAuthorizationResponse>;
  getAllCalibrateDownloads?: Maybe<Array<FieldWrapper<ICalibrateDownloadFile>>>;
  getDownloadFileByIds: Array<FieldWrapper<IDownloadFile>>;
  getDataMatchingAccounts: Array<FieldWrapper<IAccount>>;
  getDataMatchingUploads: FieldWrapper<IDataMatchingUploadsPage>;
  getDataMatchingTabs: Array<FieldWrapper<IDataMatcherTab>>;
  getDataMatcherById?: Maybe<FieldWrapper<IDataMatcher>>;
  getDataMatchingControlsWorkflowAcinControls: FieldWrapper<IDataMatchingAcinControlPage>;
  getDataMatchingControlsWorkflowUnassignedAcinControls: FieldWrapper<IDataMatchingUnassignedAcinControls>;
  getDataMatchingControlsWorkflowUnassignedAcinControlsSuggestions: FieldWrapper<IDataMatchingUnassignedAcinControlsSuggestionsPage>;
  getDataMatcherClientControlMatchingHistory?: Maybe<FieldWrapper<IDataMatcherClientControlMatchingHistory>>;
  getUnresolvedMatchingInfo: FieldWrapper<IUnresolvedMatchingInfo>;
  getDataMatchingUploadById?: Maybe<FieldWrapper<IDataMatchingFile>>;
  getFoldersAndFiles?: Maybe<Array<Maybe<FieldWrapper<IStorageFileType>>>>;
  getUploadUrl: FieldWrapper<Scalars['String']>;
  getDownloadUrl: FieldWrapper<Scalars['String']>;
  getMatchingJobs: FieldWrapper<IMatchingJobsResponse>;
  getMatchingJobControls: FieldWrapper<IMatchingJobsControlResponse>;
  getUnclearQualityDescriptors: FieldWrapper<IUnclearQualityDescriptorResponse>;
  getMatchingControls: FieldWrapper<IMatchingControlsResponse>;
  getMatchingJobClusters: FieldWrapper<IMatchingJobClustersResponse>;
  getMatchingJobClustersFilters: FieldWrapper<IFilterResponse>;
  getUnmatchedIndexControls?: Maybe<FieldWrapper<IUnmatchedIndexControlsResponse>>;
  searchMatchingControls: FieldWrapper<ISearchMatchingControlsResponse>;
  searchMatchingJobControls: FieldWrapper<ISearchMatchingControlsResponse>;
  getConnectionUsers: FieldWrapper<IConnectionUsersResponse>;
  getTemplateQueries: Array<FieldWrapper<IReportCollection>>;
  getTemplateQueryById?: Maybe<FieldWrapper<ITemplateQuery>>;
  getCompletenessReportResults: FieldWrapper<ICompletenessReportResult>;
  getCompletenessReportResultsByRiskIntelId: FieldWrapper<ICompletenessReportResult>;
  validateReportName: FieldWrapper<IValidateReportNameResult>;
  getCompletenessOverTime: FieldWrapper<ICompletenessOverTimeReportResult>;
  getStatusReportChart?: Maybe<FieldWrapper<IStatusReportChart>>;
  getFeedInsights: FieldWrapper<IFeedInsightsPage>;
  getDashboardControlAlignment: FieldWrapper<IDashboardControlAlignment>;
  getDashboardNetworkAlignment: FieldWrapper<IDashboardNetworkAlignment>;
  getControlsByInventorySummary: FieldWrapper<ICompletenessReportResultTableChart>;
  getDashboardReports: FieldWrapper<IDashboardReports>;
  getRiskIntelligenceFeed: FieldWrapper<IRiskIntelligenceFeed>;
  getDataOverview: FieldWrapper<IDataOverview>;
  searchAcinControls: FieldWrapper<ISearchAcinControlsPage>;
  getAcinControlById: FieldWrapper<IAcinControl>;
  getControlData: FieldWrapper<IClientControlData>;
  getClientControlById: FieldWrapper<IClientControl>;
  getClientControlByIds?: Maybe<Array<FieldWrapper<IClientControl>>>;
  getControlById?: Maybe<FieldWrapper<IControl>>;
  searchRiskInventories: FieldWrapper<ISearchRiskInventoriesPage>;
  searchRegulators: FieldWrapper<ISearchRegulatorsPage>;
  getControlInstanceOptions: FieldWrapper<IFilterV2>;
  getClientRisks: FieldWrapper<IClientRiskPage>;
  getClientRiskById: FieldWrapper<IClientRisk>;
  getRiskById?: Maybe<FieldWrapper<IRisk>>;
  getRiskAnalysisById: FieldWrapper<IRiskAnalysisResult>;
  searchAcinRisks: FieldWrapper<ISearchAcinRisksPage>;
  getBusinessLineById: FieldWrapper<IBusinessLine>;
  getBusinessLineByIds?: Maybe<Array<FieldWrapper<IBusinessLine>>>;
  getComparisonByControlId?: Maybe<FieldWrapper<ICompareControlPage>>;
  getComparisonByRiskId?: Maybe<FieldWrapper<ICompareRisk>>;
  getControlComparisonData: FieldWrapper<ICompareControlPage>;
  getControlHistory: FieldWrapper<IControlHistory>;
  getCompletenessControlTable: FieldWrapper<ITableResult>;
  getTable: FieldWrapper<ITableResult>;
  getChart: FieldWrapper<IChartResult>;
  getChartFilters: FieldWrapper<IChartFiltersResult>;
  getChartSummary: FieldWrapper<IChartSummary>;
  getChartTable?: Maybe<FieldWrapper<IChartTableResult>>;
  getChartAsTable: FieldWrapper<IChartAsTableResult>;
  getDataQualityJob: FieldWrapper<IDataQualityJob>;
  getRiskIntelligence: FieldWrapper<IRiskIntelligencePage>;
  getRiskIntelligenceById: FieldWrapper<IRiskIntelligence>;
  getHighestExposureRiskIntelligence: FieldWrapper<IRiskIntelligence>;
  getTrendingRiskIntelligence: FieldWrapper<IRiskIntelligence>;
  getRiskIntelligenceHubFilters: FieldWrapper<IRiskIntelligenceHubFilters>;
  getRiskIntelligenceAlert: FieldWrapper<IRiskIntelligenceAlert>;
  getRiskIntelligenceAlertRelatedData: FieldWrapper<IRiskIntelligenceAlertRelatedData>;
  getRiskIntelligenceAlertRisksChart: FieldWrapper<IDonutChart>;
  getRiskIntelligenceAlertControlsChart: FieldWrapper<IDonutChart>;
  getRiskIntelligenceAlertSummary: FieldWrapper<Scalars['String']>;
  getRegulatoryFinesOverTimeByRisk: FieldWrapper<IRegulatoryFinesOverTime>;
  getRegulatoryFinesByAcinControls: FieldWrapper<ITableRefresherBarChart>;
  inspect: FieldWrapper<IInspectorPage>;
  getPaginationItemsPerPage: Array<FieldWrapper<IItemsPerPageOption>>;
  getMockedPaginationResults: FieldWrapper<IMockedPaginationResults>;
  examples: Array<FieldWrapper<IExample>>;
  getGlobalSearchResults: Array<FieldWrapper<IGlobalSearchResult>>;
  getProducts?: Maybe<FieldWrapper<IProductPage>>;
  getProductById?: Maybe<FieldWrapper<IProduct>>;
  getAllReports: FieldWrapper<IAllReportsPage>;
  getAllReportsFilters: FieldWrapper<IFilterResponse>;
  getAllReportsQuickFilters: FieldWrapper<IAllReportsQuickFilterPage>;
  getReportPage: FieldWrapper<IReportPage>;
  getMyReports: FieldWrapper<IMyReports>;
  getConfiguratorOptions: Array<FieldWrapper<IFilterV2>>;
  getConfiguratorFilterOptions: FieldWrapper<IFilterResponse>;
  getConfiguratorReportById?: Maybe<FieldWrapper<IConfiguratorReportResult>>;
};


export type IQuerySearchAccountsArgs = {
  input?: Maybe<ISearchAccountsInput>;
};


export type IQueryGetAccountArgs = {
  id: Scalars['ID'];
};


export type IQueryGetUserArgs = {
  id: Scalars['ID'];
};


export type IQueryGetRolesArgs = {
  userId?: Maybe<Scalars['ID']>;
};


export type IQueryGetDownloadFileByIdsArgs = {
  ids: Array<Scalars['ID']>;
};


export type IQueryGetDataMatchingUploadsArgs = {
  input?: Maybe<IDataMatchingUploadsPageInput>;
};


export type IQueryGetDataMatchingTabsArgs = {
  input?: Maybe<IDataMatchingUploadsPageInput>;
};


export type IQueryGetDataMatcherByIdArgs = {
  id: Scalars['String'];
  filterInput?: Maybe<IFilterInput>;
};


export type IQueryGetDataMatchingControlsWorkflowAcinControlsArgs = {
  input: IDataMatchingAcinControlPageInput;
  filterInput: IFilterInput;
};


export type IQueryGetDataMatchingControlsWorkflowUnassignedAcinControlsArgs = {
  dataMatcherId: Scalars['ID'];
};


export type IQueryGetDataMatchingControlsWorkflowUnassignedAcinControlsSuggestionsArgs = {
  input: IDataMatchingUnassignedAcinControlsSuggestionsInput;
};


export type IQueryGetDataMatcherClientControlMatchingHistoryArgs = {
  input: IDataMatcherClientControlMatchingHistoryInput;
};


export type IQueryGetUnresolvedMatchingInfoArgs = {
  id: Scalars['String'];
};


export type IQueryGetDataMatchingUploadByIdArgs = {
  id: Scalars['String'];
};


export type IQueryGetFoldersAndFilesArgs = {
  accountCode: Scalars['String'];
};


export type IQueryGetDownloadUrlArgs = {
  fileName: Scalars['String'];
};


export type IQueryGetMatchingJobsArgs = {
  input: IMatchingJobsInput;
};


export type IQueryGetMatchingJobControlsArgs = {
  input: IMatchingJobControlsInput;
};


export type IQueryGetMatchingControlsArgs = {
  input?: Maybe<IMatchingControlsInput>;
};


export type IQueryGetMatchingJobClustersArgs = {
  input?: Maybe<IMatchingJobClustersInput>;
};


export type IQueryGetMatchingJobClustersFiltersArgs = {
  input: IMatchingJobClustersFiltersInput;
};


export type IQueryGetUnmatchedIndexControlsArgs = {
  input?: Maybe<IUnmatchedIndexControlsInput>;
};


export type IQuerySearchMatchingControlsArgs = {
  input: ISearchMatchingControlsInput;
};


export type IQuerySearchMatchingJobControlsArgs = {
  input: ISearchMatchingJobControlsInput;
};


export type IQueryGetConnectionUsersArgs = {
  input?: Maybe<IConnectionUsersInput>;
};


export type IQueryGetTemplateQueriesArgs = {
  filters?: Maybe<IFilterInput>;
};


export type IQueryGetTemplateQueryByIdArgs = {
  id: Scalars['ID'];
};


export type IQueryGetCompletenessReportResultsArgs = {
  configuration: IFilterInput;
  filters?: Maybe<IFilterInput>;
  reportId?: Maybe<Scalars['String']>;
};


export type IQueryGetCompletenessReportResultsByRiskIntelIdArgs = {
  id: Scalars['ID'];
  reportId?: Maybe<Scalars['String']>;
  filters?: Maybe<IFilterInput>;
};


export type IQueryValidateReportNameArgs = {
  validateReportNameInput: IValidateReportNameInput;
};


export type IQueryGetCompletenessOverTimeArgs = {
  input?: Maybe<ICompletenessOverTimeInput>;
};


export type IQueryGetStatusReportChartArgs = {
  id: Scalars['String'];
};


export type IQueryGetRiskIntelligenceFeedArgs = {
  input?: Maybe<IRiskIntelligenceFeedInput>;
};


export type IQueryGetDataOverviewArgs = {
  input?: Maybe<IDataOverviewInput>;
};


export type IQuerySearchAcinControlsArgs = {
  input?: Maybe<ISearchAcinControlsInput>;
};


export type IQueryGetAcinControlByIdArgs = {
  id: Scalars['String'];
};


export type IQueryGetControlDataArgs = {
  id: Scalars['String'];
};


export type IQueryGetClientControlByIdArgs = {
  id: Scalars['String'];
};


export type IQueryGetClientControlByIdsArgs = {
  ids: Array<Scalars['String']>;
};


export type IQueryGetControlByIdArgs = {
  id: Scalars['String'];
};


export type IQuerySearchRiskInventoriesArgs = {
  input?: Maybe<ISearchRiskInventoriesInput>;
};


export type IQuerySearchRegulatorsArgs = {
  input?: Maybe<ISearchRegulatorInput>;
};


export type IQueryGetControlInstanceOptionsArgs = {
  input?: Maybe<IControlInstanceOptionsInput>;
};


export type IQueryGetClientRisksArgs = {
  input: IGetRiskDataInput;
};


export type IQueryGetClientRiskByIdArgs = {
  id: Scalars['String'];
};


export type IQueryGetRiskByIdArgs = {
  id: Scalars['String'];
};


export type IQueryGetRiskAnalysisByIdArgs = {
  id: Scalars['ID'];
};


export type IQuerySearchAcinRisksArgs = {
  input?: Maybe<ISearchAcinRisksInput>;
};


export type IQueryGetBusinessLineByIdArgs = {
  id: Scalars['String'];
};


export type IQueryGetBusinessLineByIdsArgs = {
  ids: Array<Scalars['String']>;
};


export type IQueryGetComparisonByControlIdArgs = {
  id: Scalars['String'];
  riskInventoryId?: Maybe<Scalars['String']>;
};


export type IQueryGetComparisonByRiskIdArgs = {
  id: Scalars['String'];
};


export type IQueryGetControlComparisonDataArgs = {
  input?: Maybe<IControlComparisonDataInput>;
};


export type IQueryGetControlHistoryArgs = {
  input?: Maybe<IControlHistoryInput>;
};


export type IQueryGetCompletenessControlTableArgs = {
  input?: Maybe<ITableInput>;
};


export type IQueryGetTableArgs = {
  input?: Maybe<ITableInput>;
};


export type IQueryGetChartArgs = {
  input: IChartInput;
};


export type IQueryGetChartFiltersArgs = {
  input: IChartInput;
};


export type IQueryGetChartSummaryArgs = {
  input: IChartInput;
};


export type IQueryGetChartTableArgs = {
  input: IChartInput;
};


export type IQueryGetChartAsTableArgs = {
  input: IChartAsTableInput;
};


export type IQueryGetDataQualityJobArgs = {
  jobId: Scalars['String'];
};


export type IQueryGetRiskIntelligenceArgs = {
  input?: Maybe<IRiskIntelligenceInput>;
};


export type IQueryGetRiskIntelligenceByIdArgs = {
  id: Scalars['String'];
};


export type IQueryGetRiskIntelligenceAlertArgs = {
  input: IRiskIntelligenceAlertInput;
};


export type IQueryGetRiskIntelligenceAlertRelatedDataArgs = {
  input: IRiskIntelligenceAlertInput;
};


export type IQueryGetRiskIntelligenceAlertRisksChartArgs = {
  input: IRiskIntelligenceAlertInput;
};


export type IQueryGetRiskIntelligenceAlertControlsChartArgs = {
  input: IRiskIntelligenceAlertInput;
};


export type IQueryGetRiskIntelligenceAlertSummaryArgs = {
  input: IRiskIntelligenceAlertInput;
};


export type IQueryGetRegulatoryFinesOverTimeByRiskArgs = {
  input: IRegulatoryFinesInput;
};


export type IQueryGetRegulatoryFinesByAcinControlsArgs = {
  input: IRegulatoryFinesInput;
};


export type IQueryInspectArgs = {
  input: IInspectorInput;
};


export type IQueryGetMockedPaginationResultsArgs = {
  input: IPaginationInput;
};


export type IQueryGetGlobalSearchResultsArgs = {
  searchTerm: Scalars['String'];
  resultTypes?: Maybe<Array<Maybe<IGlobalSearchResultAssetType>>>;
};


export type IQueryGetProductsArgs = {
  input?: Maybe<IProductInput>;
};


export type IQueryGetProductByIdArgs = {
  id: Scalars['String'];
};


export type IQueryGetAllReportsArgs = {
  input?: Maybe<IAllReportsInput>;
};


export type IQueryGetReportPageArgs = {
  input: IReportPageInput;
};


export type IQueryGetMyReportsArgs = {
  input: IMyReportsInput;
};


export type IQueryGetConfiguratorOptionsArgs = {
  input?: Maybe<IConfiguratorOptionsInput>;
  pageCode?: Maybe<IPageCode>;
};


export type IQueryGetConfiguratorFilterOptionsArgs = {
  input: IConfiguratorOptionsInput;
};


export type IQueryGetConfiguratorReportByIdArgs = {
  id: Scalars['ID'];
};

export enum IReadPermission {
  Private = 'PRIVATE',
  Organisation = 'ORGANISATION'
}

export type IReasonCode = {
  __typename?: 'ReasonCode';
  id: FieldWrapper<Scalars['String']>;
  codeType: FieldWrapper<IReasonCodeType>;
  shortName: FieldWrapper<Scalars['String']>;
  longName: FieldWrapper<Scalars['String']>;
  clientFacingName: FieldWrapper<Scalars['String']>;
  riskInventories: Array<FieldWrapper<IRiskInventory>>;
  colorPalette?: Maybe<FieldWrapper<IColorPalette>>;
};

export enum IReasonCodeType {
  A = 'A',
  B = 'B',
  C = 'C',
  R = 'R'
}

export type IRegulation = {
  __typename?: 'Regulation';
  id: FieldWrapper<Scalars['ID']>;
  name: FieldWrapper<Scalars['String']>;
  regulator: FieldWrapper<IRegulator>;
  articles?: Maybe<Array<FieldWrapper<IRegulatoryArticle>>>;
};

export type IRegulator = {
  __typename?: 'Regulator';
  id: FieldWrapper<Scalars['ID']>;
  name: FieldWrapper<Scalars['String']>;
  regulations: Array<FieldWrapper<IRegulation>>;
  allArticles: Array<FieldWrapper<IRegulatoryArticle>>;
};

export type IRegulatoryArticle = {
  __typename?: 'RegulatoryArticle';
  id: FieldWrapper<Scalars['ID']>;
  name: FieldWrapper<Scalars['String']>;
  url?: Maybe<FieldWrapper<Scalars['String']>>;
};

export type IRegulatoryFinesInput = {
  configuration?: Maybe<Array<IFilterInputGroup>>;
  filters?: Maybe<IFilterInput>;
};

export type IRegulatoryFinesOverTime = {
  __typename?: 'RegulatoryFinesOverTime';
  chart: FieldWrapper<ITableRefresherBarChart>;
  yAxisLabel: FieldWrapper<Scalars['String']>;
};

export type IRelatedRiskIntelligence = IRiskIntelligence & {
  __typename?: 'RelatedRiskIntelligence';
  id: FieldWrapper<Scalars['ID']>;
  publishDate: FieldWrapper<Scalars['String']>;
  title: FieldWrapper<Scalars['String']>;
  type: FieldWrapper<IRiskIntelligenceType>;
  createdAt: FieldWrapper<Scalars['String']>;
  description: FieldWrapper<Scalars['String']>;
  content: FieldWrapper<Scalars['String']>;
  authorTitle: FieldWrapper<Scalars['String']>;
  hasAnalyticsData: FieldWrapper<Scalars['Boolean']>;
  lockedInventoryGroup?: Maybe<FieldWrapper<IRiskIntelligenceLockedInventoryGroup>>;
  favourite: FieldWrapper<Scalars['Boolean']>;
  impact: FieldWrapper<IRiskIntelligenceImpact>;
};

export type IRelationshipInsight = {
  __typename?: 'RelationshipInsight';
  name: FieldWrapper<Scalars['String']>;
  description: FieldWrapper<Scalars['String']>;
};

export type IRelationshipInsightContext = {
  __typename?: 'RelationshipInsightContext';
  colorPalette: FieldWrapper<IColorPalette>;
  insight?: Maybe<FieldWrapper<IRelationshipInsight>>;
  externalComment?: Maybe<FieldWrapper<Scalars['String']>>;
};

export type IReport = {
  __typename?: 'Report';
  id: FieldWrapper<Scalars['ID']>;
  title: FieldWrapper<Scalars['String']>;
  description: FieldWrapper<Scalars['String']>;
  favourite?: Maybe<FieldWrapper<Scalars['Boolean']>>;
  createdAt: FieldWrapper<Scalars['String']>;
  lastViewedDate?: Maybe<FieldWrapper<Scalars['String']>>;
  author: FieldWrapper<IUser>;
  visibility: FieldWrapper<IReadPermission>;
  isConfiguratorReport?: Maybe<FieldWrapper<Scalars['Boolean']>>;
  canDelete?: Maybe<FieldWrapper<Scalars['Boolean']>>;
};

export type IReportCard = {
  __typename?: 'ReportCard';
  id: FieldWrapper<Scalars['String']>;
  title?: Maybe<FieldWrapper<Scalars['String']>>;
  description?: Maybe<FieldWrapper<Scalars['String']>>;
  isConfiguratorReport?: Maybe<FieldWrapper<Scalars['Boolean']>>;
};

export type IReportChartCard = {
  __typename?: 'ReportChartCard';
  id: FieldWrapper<Scalars['String']>;
  title: FieldWrapper<Scalars['String']>;
  chartType: FieldWrapper<IReportChartType>;
  configuration: Array<FieldWrapper<IFilterPayloadInputGroup>>;
  variant: FieldWrapper<ITableVariant>;
};

export enum IReportChartType {
  Horizontal = 'HORIZONTAL',
  Vertical = 'VERTICAL',
  Table = 'TABLE',
  Summary = 'SUMMARY'
}

export type IReportCollection = {
  __typename?: 'ReportCollection';
  tabId: FieldWrapper<Scalars['ID']>;
  groups: Array<FieldWrapper<ITemplateGroup>>;
  reportCount: FieldWrapper<Scalars['Int']>;
};

export type IReportPage = {
  __typename?: 'ReportPage';
  title?: Maybe<FieldWrapper<Scalars['String']>>;
  description?: Maybe<FieldWrapper<Scalars['String']>>;
  charts: Array<FieldWrapper<IReportChartCard>>;
  sections?: Maybe<Array<FieldWrapper<IReportPageSection>>>;
};

export type IReportPageInput = {
  pageCode: IPageCode;
};

export type IReportPageSection = {
  __typename?: 'ReportPageSection';
  title?: Maybe<FieldWrapper<Scalars['String']>>;
  description?: Maybe<FieldWrapper<Scalars['String']>>;
  reports: Array<FieldWrapper<IReportCard>>;
};

export enum IReportType {
  HorizontalBar = 'HORIZONTAL_BAR',
  HorizontalBarDataVis = 'HORIZONTAL_BAR_DATA_VIS',
  HorizontalBarInsightCodes = 'HORIZONTAL_BAR_INSIGHT_CODES',
  HorizontalBarInsightCodesStacked = 'HORIZONTAL_BAR_INSIGHT_CODES_STACKED',
  VerticalBar = 'VERTICAL_BAR',
  VerticalBarDataVis = 'VERTICAL_BAR_DATA_VIS',
  VerticalBarInsightCodes = 'VERTICAL_BAR_INSIGHT_CODES',
  VerticalBarInsightCodesStacked = 'VERTICAL_BAR_INSIGHT_CODES_STACKED',
  Table = 'TABLE',
  TableDataVis = 'TABLE_DATA_VIS',
  TableInsightCodes = 'TABLE_INSIGHT_CODES',
  TableInsightCodesStacked = 'TABLE_INSIGHT_CODES_STACKED',
  OverTimeDataVis = 'OVER_TIME_DATA_VIS',
  OverTimeInsightCodes = 'OVER_TIME_INSIGHT_CODES',
  PieChartInsightCodes = 'PIE_CHART_INSIGHT_CODES'
}

export type IRisk = IClientRisk | IAcinRisk;

export type IRiskAlignmentOverviewChart = {
  __typename?: 'RiskAlignmentOverviewChart';
  id: FieldWrapper<Scalars['ID']>;
  name: FieldWrapper<Scalars['String']>;
  description: FieldWrapper<Scalars['String']>;
  keys: Array<FieldWrapper<IChartKey>>;
  data: Array<FieldWrapper<IRiskAlignmentOverviewChartValue>>;
};

export type IRiskAlignmentOverviewChartCollection = {
  __typename?: 'RiskAlignmentOverviewChartCollection';
  name?: Maybe<FieldWrapper<Scalars['String']>>;
  totalRisks: FieldWrapper<Scalars['Int']>;
  items: Array<FieldWrapper<IRisk>>;
};

export type IRiskAlignmentOverviewChartValue = {
  __typename?: 'RiskAlignmentOverviewChartValue';
  name: FieldWrapper<Scalars['String']>;
  description: FieldWrapper<Scalars['String']>;
  total: FieldWrapper<Scalars['Int']>;
  colorPalette: FieldWrapper<IColorPalette>;
  collection: Array<FieldWrapper<IRiskAlignmentOverviewChartCollection>>;
};

export type IRiskAnalysisResult = {
  __typename?: 'RiskAnalysisResult';
  id: FieldWrapper<Scalars['ID']>;
  chart: FieldWrapper<IDashboardControlAlignment>;
  controlList: FieldWrapper<IRiskDetailsControlList>;
};

export type IRiskDetailsControlList = ICompletenessReportControlListPageGroup;

export type IRiskIntelligence = {
  id: FieldWrapper<Scalars['ID']>;
  publishDate: FieldWrapper<Scalars['String']>;
  title: FieldWrapper<Scalars['String']>;
  type: FieldWrapper<IRiskIntelligenceType>;
  createdAt: FieldWrapper<Scalars['String']>;
  description: FieldWrapper<Scalars['String']>;
  content: FieldWrapper<Scalars['String']>;
  authorTitle: FieldWrapper<Scalars['String']>;
  hasAnalyticsData: FieldWrapper<Scalars['Boolean']>;
  lockedInventoryGroup?: Maybe<FieldWrapper<IRiskIntelligenceLockedInventoryGroup>>;
  favourite: FieldWrapper<Scalars['Boolean']>;
  impact: FieldWrapper<IRiskIntelligenceImpact>;
};

export type IRiskIntelligenceAlert = {
  __typename?: 'RiskIntelligenceAlert';
  title: FieldWrapper<Scalars['String']>;
};

export type IRiskIntelligenceAlertInput = {
  id: Scalars['String'];
  insightBy?: Maybe<IRiskIntelligenceAlertInsightType>;
};

export enum IRiskIntelligenceAlertInsightType {
  BusinessLine = 'BUSINESS_LINE',
  Index = 'INDEX'
}

export type IRiskIntelligenceAlertRelatedData = {
  __typename?: 'RiskIntelligenceAlertRelatedData';
  sources: Array<FieldWrapper<IRiskIntelligenceSource>>;
  regulations: Array<FieldWrapper<IRiskIntelligenceRegulation>>;
  fine?: Maybe<FieldWrapper<Scalars['String']>>;
  indexRisks: Array<FieldWrapper<IRiskIntelligenceRisk>>;
};

export type IRiskIntelligenceFeed = {
  __typename?: 'RiskIntelligenceFeed';
  alerts: Array<FieldWrapper<IRiskIntelligenceFeedAlert>>;
};

export type IRiskIntelligenceFeedAlert = {
  __typename?: 'RiskIntelligenceFeedAlert';
  id: FieldWrapper<Scalars['ID']>;
  type: FieldWrapper<IRiskIntelligenceType>;
  title: FieldWrapper<Scalars['String']>;
  description: FieldWrapper<Scalars['String']>;
  datePublished: FieldWrapper<Scalars['String']>;
  fineValue?: Maybe<FieldWrapper<Scalars['String']>>;
  clientControlCount?: Maybe<FieldWrapper<Scalars['Int']>>;
  clientRiskCount?: Maybe<FieldWrapper<Scalars['Int']>>;
};

export type IRiskIntelligenceFeedInput = {
  filters?: Maybe<IFilterInput>;
  pagination?: Maybe<IPaginationInput>;
};

export type IRiskIntelligenceHubFilters = {
  __typename?: 'RiskIntelligenceHubFilters';
  availableFilters?: Maybe<FieldWrapper<IFilters>>;
};

export type IRiskIntelligenceImpact = {
  __typename?: 'RiskIntelligenceImpact';
  totalImpactedRisks: FieldWrapper<Scalars['Int']>;
  totalImpactedControls: FieldWrapper<Scalars['Int']>;
  totalImpactedRiskInventories: FieldWrapper<Scalars['Int']>;
  totalImpactedBusinessLines: FieldWrapper<Scalars['Int']>;
};

export type IRiskIntelligenceInput = {
  filters?: Maybe<IFilterInput>;
  sorting?: Maybe<ISortInput>;
  pagination?: Maybe<IPaginationInput>;
};

export type IRiskIntelligenceLockedInventoryGroup = {
  __typename?: 'RiskIntelligenceLockedInventoryGroup';
  nodes: Array<FieldWrapper<IRiskIntelligenceLockedInventoryGroupItem>>;
};

export type IRiskIntelligenceLockedInventoryGroupItem = {
  __typename?: 'RiskIntelligenceLockedInventoryGroupItem';
  id: FieldWrapper<Scalars['ID']>;
  riskInventory: FieldWrapper<IRiskInventory>;
  totalImpactedAcinControls: FieldWrapper<Scalars['Int']>;
};

export type IRiskIntelligenceNetworkScenario = IRiskIntelligence & {
  __typename?: 'RiskIntelligenceNetworkScenario';
  id: FieldWrapper<Scalars['ID']>;
  publishDate: FieldWrapper<Scalars['String']>;
  title: FieldWrapper<Scalars['String']>;
  type: FieldWrapper<IRiskIntelligenceType>;
  createdAt: FieldWrapper<Scalars['String']>;
  description: FieldWrapper<Scalars['String']>;
  content: FieldWrapper<Scalars['String']>;
  authorTitle: FieldWrapper<Scalars['String']>;
  incidentType: FieldWrapper<Scalars['String']>;
  sources: Array<FieldWrapper<IRiskIntelligenceSource>>;
  regulations: Array<FieldWrapper<IRiskIntelligenceRegulation>>;
  impact: FieldWrapper<IRiskIntelligenceImpact>;
  lockedInventoryGroup?: Maybe<FieldWrapper<IRiskIntelligenceLockedInventoryGroup>>;
  favourite: FieldWrapper<Scalars['Boolean']>;
  hasAnalyticsData: FieldWrapper<Scalars['Boolean']>;
};

export type IRiskIntelligenceNetworkUpdate = IRiskIntelligence & {
  __typename?: 'RiskIntelligenceNetworkUpdate';
  id: FieldWrapper<Scalars['ID']>;
  publishDate: FieldWrapper<Scalars['String']>;
  title: FieldWrapper<Scalars['String']>;
  type: FieldWrapper<IRiskIntelligenceType>;
  createdAt: FieldWrapper<Scalars['String']>;
  description: FieldWrapper<Scalars['String']>;
  content: FieldWrapper<Scalars['String']>;
  authorTitle: FieldWrapper<Scalars['String']>;
  incidentType?: Maybe<FieldWrapper<Scalars['String']>>;
  status: FieldWrapper<Scalars['String']>;
  sources: Array<FieldWrapper<IRiskIntelligenceSource>>;
  regulations: Array<FieldWrapper<IRiskIntelligenceRegulation>>;
  impact: FieldWrapper<IRiskIntelligenceImpact>;
  hasAnalyticsData: FieldWrapper<Scalars['Boolean']>;
  lockedInventoryGroup?: Maybe<FieldWrapper<IRiskIntelligenceLockedInventoryGroup>>;
  favourite: FieldWrapper<Scalars['Boolean']>;
};

export type IRiskIntelligencePage = {
  __typename?: 'RiskIntelligencePage';
  pagination?: Maybe<FieldWrapper<IPageInfo>>;
  nodes: Array<FieldWrapper<IRiskIntelligence>>;
  activeSorting?: Maybe<FieldWrapper<ISortField>>;
  unfilteredTotal?: Maybe<FieldWrapper<Scalars['Int']>>;
};

export type IRiskIntelligenceRegulation = {
  __typename?: 'RiskIntelligenceRegulation';
  regulator?: Maybe<FieldWrapper<IRegulator>>;
  regulation?: Maybe<FieldWrapper<IRegulation>>;
  regulationArticles: Array<FieldWrapper<IRegulatoryArticle>>;
};

export type IRiskIntelligenceRegulatoryAlert = IRiskIntelligence & {
  __typename?: 'RiskIntelligenceRegulatoryAlert';
  id: FieldWrapper<Scalars['ID']>;
  publishDate: FieldWrapper<Scalars['String']>;
  title: FieldWrapper<Scalars['String']>;
  type: FieldWrapper<IRiskIntelligenceType>;
  createdAt: FieldWrapper<Scalars['String']>;
  description: FieldWrapper<Scalars['String']>;
  content: FieldWrapper<Scalars['String']>;
  authorTitle: FieldWrapper<Scalars['String']>;
  incidentType: FieldWrapper<Scalars['String']>;
  sources: Array<FieldWrapper<IRiskIntelligenceSource>>;
  regulations: Array<FieldWrapper<IRiskIntelligenceRegulation>>;
  impact: FieldWrapper<IRiskIntelligenceImpact>;
  favourite: FieldWrapper<Scalars['Boolean']>;
  hasAnalyticsData: FieldWrapper<Scalars['Boolean']>;
  lockedInventoryGroup?: Maybe<FieldWrapper<IRiskIntelligenceLockedInventoryGroup>>;
  originalPublishDate: FieldWrapper<Scalars['String']>;
  originalLastUpdated: FieldWrapper<Scalars['String']>;
  classification: FieldWrapper<Scalars['String']>;
};

export type IRiskIntelligenceRisk = {
  __typename?: 'RiskIntelligenceRisk';
  id: FieldWrapper<Scalars['String']>;
  code: FieldWrapper<Scalars['String']>;
  name: FieldWrapper<Scalars['String']>;
};

export type IRiskIntelligenceSource = {
  __typename?: 'RiskIntelligenceSource';
  label?: Maybe<FieldWrapper<Scalars['String']>>;
  url: FieldWrapper<Scalars['String']>;
};

export type IRiskIntelligenceTableItem = {
  __typename?: 'RiskIntelligenceTableItem';
  id: FieldWrapper<Scalars['ID']>;
  publishDate: FieldWrapper<Scalars['String']>;
  title: FieldWrapper<Scalars['String']>;
  type: FieldWrapper<IRiskIntelligenceType>;
  impact: FieldWrapper<IRiskIntelligenceImpact>;
  favourite: FieldWrapper<Scalars['Boolean']>;
};

export enum IRiskIntelligenceType {
  NetworkScenario = 'NetworkScenario',
  RegulatoryAlert = 'RegulatoryAlert',
  NetworkUpdate = 'NetworkUpdate'
}

export type IRiskInventory = {
  __typename?: 'RiskInventory';
  id: FieldWrapper<Scalars['ID']>;
  name: FieldWrapper<Scalars['String']>;
};

export type IRiskInventoryHistory = {
  __typename?: 'RiskInventoryHistory';
  id: FieldWrapper<Scalars['ID']>;
  name: FieldWrapper<Scalars['String']>;
};

export type IRole = {
  __typename?: 'Role';
  id: FieldWrapper<Scalars['ID']>;
  name: FieldWrapper<Scalars['String']>;
  description?: Maybe<FieldWrapper<Scalars['String']>>;
};

export type ISearchAccountsInput = {
  term?: Maybe<Scalars['String']>;
  riskInventoryIds?: Maybe<Array<Scalars['String']>>;
};

export type ISearchAccountsPage = {
  __typename?: 'SearchAccountsPage';
  totalAccounts: FieldWrapper<Scalars['Int']>;
  nodes: Array<FieldWrapper<IAccount>>;
};

export type ISearchAcinControlsInput = {
  term?: Maybe<Scalars['String']>;
  riskInventoryIds?: Maybe<Array<Scalars['String']>>;
};

export type ISearchAcinControlsPage = {
  __typename?: 'SearchAcinControlsPage';
  totalAvailableControls: FieldWrapper<Scalars['Int']>;
  nodes: Array<FieldWrapper<IAcinControl>>;
};

export type ISearchAcinRisksInput = {
  term?: Maybe<Scalars['String']>;
  riskInventoryIds?: Maybe<Array<Scalars['String']>>;
};

export type ISearchAcinRisksPage = {
  __typename?: 'SearchAcinRisksPage';
  nodes: Array<FieldWrapper<IAcinRisk>>;
};

export type ISearchMatchingControlsInput = {
  accountCode?: Maybe<Scalars['String']>;
  searchTerm: Scalars['String'];
};

export type ISearchMatchingControlsResponse = {
  __typename?: 'SearchMatchingControlsResponse';
  controls: Array<FieldWrapper<IMatchingControl>>;
};

export type ISearchMatchingJobControlsInput = {
  accountCode: Scalars['String'];
  matchingJobId: Scalars['String'];
  searchTerm: Scalars['String'];
};

export type ISearchRegulatorInput = {
  term?: Maybe<Scalars['String']>;
};

export type ISearchRegulatorsPage = {
  __typename?: 'SearchRegulatorsPage';
  nodes: Array<FieldWrapper<IRegulator>>;
};

export type ISearchRiskInventoriesInput = {
  term?: Maybe<Scalars['String']>;
};

export type ISearchRiskInventoriesPage = {
  __typename?: 'SearchRiskInventoriesPage';
  nodes: Array<FieldWrapper<IRiskInventory>>;
};

export type ISecondaryBarChartValue = {
  __typename?: 'SecondaryBarChartValue';
  text: FieldWrapper<Scalars['String']>;
  isAcin?: Maybe<FieldWrapper<Scalars['Boolean']>>;
};

export enum ISentiment {
  Positive = 'POSITIVE',
  Neutral = 'NEUTRAL',
  Negative = 'NEGATIVE'
}

export enum ISortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type ISortField = {
  __typename?: 'SortField';
  field: FieldWrapper<Scalars['String']>;
  direction: FieldWrapper<ISortDirection>;
  label: FieldWrapper<Scalars['String']>;
};

export type ISortInfo = {
  __typename?: 'SortInfo';
  sort: FieldWrapper<ISortField>;
  available: Array<FieldWrapper<ISortField>>;
};

export type ISortInput = {
  direction: ISortDirection;
  field: Scalars['String'];
};

export type IStatusReport = {
  __typename?: 'StatusReport';
  id: FieldWrapper<Scalars['ID']>;
  availableCharts: Array<FieldWrapper<IAvailableChart>>;
  benchmarkOverview: FieldWrapper<IBenchmarkOverview>;
};

export type IStatusReportChart = IControlAlignmentOverviewChart | IRiskAlignmentOverviewChart;

export type IStorageFileType = {
  __typename?: 'StorageFileType';
  id: FieldWrapper<Scalars['ID']>;
  isFolder: FieldWrapper<Scalars['Boolean']>;
  name: FieldWrapper<Scalars['String']>;
};

export type ISubmitClustersForApprovalInput = {
  accountCode: Scalars['String'];
  matchingJobId: Scalars['String'];
  clusterIds: Array<Scalars['String']>;
};

export type ISubmitClustersForApprovalResponse = {
  __typename?: 'SubmitClustersForApprovalResponse';
  clusters: Array<FieldWrapper<IMatchingJobCluster>>;
};

export type ISubmitControlsForApprovalInput = {
  accountCode: Scalars['String'];
  matchingJobId: Scalars['String'];
  controlIds: Array<Scalars['String']>;
};

export type ISubmitControlsForApprovalResponse = {
  __typename?: 'SubmitControlsForApprovalResponse';
  controls: Array<FieldWrapper<IMatchingJobControl>>;
};

export type ISuggestedMatch = {
  __typename?: 'SuggestedMatch';
  control: FieldWrapper<IMatchingControl>;
  certainty: FieldWrapper<Scalars['Float']>;
};

export type ITable = {
  __typename?: 'Table';
  headers?: Maybe<FieldWrapper<ITableHeader>>;
  rows: Array<FieldWrapper<ITableRow>>;
};

export type ITableCell = {
  __typename?: 'TableCell';
  id: FieldWrapper<Scalars['String']>;
  key: FieldWrapper<Scalars['String']>;
  text?: Maybe<FieldWrapper<Scalars['String']>>;
  count?: Maybe<FieldWrapper<Scalars['Int']>>;
  content?: Maybe<FieldWrapper<ITableCellContent>>;
  info?: Maybe<FieldWrapper<ITableCellInfo>>;
};

export type ITableCellContent = ITableInsightCategory | INetworkSignalScore | ITableInsightCounts | ITableControlQualityScore;

export type ITableCellInfo = {
  __typename?: 'TableCellInfo';
  showAcinLogo?: Maybe<FieldWrapper<Scalars['Boolean']>>;
  isAcin?: Maybe<FieldWrapper<Scalars['Boolean']>>;
  isLink?: Maybe<FieldWrapper<Scalars['Boolean']>>;
  id?: Maybe<FieldWrapper<Scalars['String']>>;
  entityId?: Maybe<FieldWrapper<Scalars['String']>>;
  type?: Maybe<FieldWrapper<Scalars['String']>>;
  businessOrgId?: Maybe<FieldWrapper<Scalars['String']>>;
  riskInventoryId?: Maybe<FieldWrapper<Scalars['String']>>;
};

export type ITableControlQualityScore = {
  __typename?: 'TableControlQualityScore';
  controlQualityScore: FieldWrapper<IControlQualityScore>;
};

export type ITableHeader = {
  __typename?: 'TableHeader';
  variant: FieldWrapper<ITableHeaderType>;
  cells?: Maybe<Array<FieldWrapper<ITableCell>>>;
};

export enum ITableHeaderType {
  Outer = 'OUTER',
  Inner = 'INNER'
}

export type ITableInput = {
  id?: Maybe<Scalars['String']>;
  variant: ITableVariant;
  configuration?: Maybe<Array<IFilterInputGroup>>;
  filters?: Maybe<IFilterInput>;
  pagination?: Maybe<IPaginationInput>;
};

export type ITableInsightCategory = {
  __typename?: 'TableInsightCategory';
  id: FieldWrapper<Scalars['String']>;
  label: FieldWrapper<Scalars['String']>;
  colorPalette: FieldWrapper<IColorPalette>;
};

export type ITableInsightCountItem = {
  __typename?: 'TableInsightCountItem';
  label: FieldWrapper<Scalars['String']>;
  count: FieldWrapper<Scalars['Int']>;
  colorPalette: FieldWrapper<IColorPalette>;
};

export type ITableInsightCounts = {
  __typename?: 'TableInsightCounts';
  nodes: Array<FieldWrapper<ITableInsightCountItem>>;
};

export type ITableRefresherBarChart = {
  __typename?: 'TableRefresherBarChart';
  id?: Maybe<FieldWrapper<Scalars['String']>>;
  chartTitle?: Maybe<FieldWrapper<Scalars['String']>>;
  negativeKeys: Array<FieldWrapper<IChartKey>>;
  positiveKeys: Array<FieldWrapper<IChartKey>>;
  data: Array<FieldWrapper<ITableRefresherBarChartGroup>>;
  nameHeader?: Maybe<FieldWrapper<ITableRefresherBarChartHeader>>;
  valueHeader: FieldWrapper<ITableRefresherBarChartHeader>;
  secondaryHeader?: Maybe<FieldWrapper<ITableRefresherBarChartHeader>>;
  hideLegend?: Maybe<FieldWrapper<Scalars['Boolean']>>;
};

export type ITableRefresherBarChartGroup = {
  __typename?: 'TableRefresherBarChartGroup';
  id: FieldWrapper<Scalars['ID']>;
  name: FieldWrapper<Scalars['String']>;
  showName?: Maybe<FieldWrapper<Scalars['Boolean']>>;
  filters?: Maybe<FieldWrapper<IFilterPayload>>;
  secondaryValue?: Maybe<FieldWrapper<ISecondaryBarChartValue>>;
  values: Array<FieldWrapper<ITableRefresherBarChartValue>>;
  info?: Maybe<FieldWrapper<IDrillDownInfo>>;
};

export type ITableRefresherBarChartHeader = {
  __typename?: 'TableRefresherBarChartHeader';
  sortable: FieldWrapper<Scalars['Boolean']>;
  name: FieldWrapper<Scalars['String']>;
  key: FieldWrapper<Scalars['String']>;
  direction?: Maybe<FieldWrapper<ISortDirection>>;
};

export type ITableRefresherBarChartValue = {
  __typename?: 'TableRefresherBarChartValue';
  key: FieldWrapper<Scalars['String']>;
  name: FieldWrapper<Scalars['String']>;
  total: FieldWrapper<Scalars['Float']>;
  percentage?: Maybe<FieldWrapper<Scalars['Int']>>;
  id?: Maybe<FieldWrapper<Scalars['String']>>;
  info?: Maybe<FieldWrapper<IDrillDownInfo>>;
};

export type ITableResult = {
  __typename?: 'TableResult';
  id: FieldWrapper<Scalars['ID']>;
  table: FieldWrapper<ITable>;
  title?: Maybe<FieldWrapper<Scalars['String']>>;
  pagination?: Maybe<FieldWrapper<IPageInfo>>;
};

export type ITableRow = {
  __typename?: 'TableRow';
  id: FieldWrapper<Scalars['String']>;
  variant: FieldWrapper<ITableRowType>;
  cells: Array<FieldWrapper<ITableCell>>;
  rows?: Maybe<Array<FieldWrapper<ITableRow>>>;
  table?: Maybe<FieldWrapper<ITable>>;
};

export enum ITableRowType {
  Grandparent = 'GRANDPARENT',
  Parent = 'PARENT',
  Child = 'CHILD'
}

export enum ITableVariant {
  Instance = 'INSTANCE',
  Distinct = 'DISTINCT'
}

export type ITemplateGroup = {
  __typename?: 'TemplateGroup';
  id: FieldWrapper<Scalars['ID']>;
  title: FieldWrapper<Scalars['String']>;
  description: FieldWrapper<Scalars['String']>;
  lockedGroup: FieldWrapper<Scalars['Boolean']>;
  nodes: Array<FieldWrapper<ITemplateQuery>>;
};

export type ITemplateQuery = {
  __typename?: 'TemplateQuery';
  id: FieldWrapper<Scalars['ID']>;
  date: FieldWrapper<Scalars['String']>;
  favourite: FieldWrapper<Scalars['Boolean']>;
  title: FieldWrapper<Scalars['String']>;
  description: FieldWrapper<Scalars['String']>;
  lockedQuery: FieldWrapper<Scalars['Boolean']>;
  comparison: FieldWrapper<Scalars['String']>;
  result?: Maybe<FieldWrapper<ITemplateQueryResult>>;
  activeFilters?: Maybe<FieldWrapper<IFilterPayload>>;
  activeConfiguration?: Maybe<FieldWrapper<IFilterPayload>>;
  author?: Maybe<FieldWrapper<IUser>>;
  shared: FieldWrapper<Scalars['Boolean']>;
};


export type ITemplateQueryResultArgs = {
  input?: Maybe<ITemplateQueryResultInput>;
};

export type ITemplateQueryResult = IClientControlsByRisk | IStatusReport | IAcinControlsByRisk | ICompletenessReport | ICompletenessOverTimeReport;

export type ITemplateQueryResultInput = {
  filters?: Maybe<IFilterInput>;
};

export enum IUiTheme {
  Default = 'DEFAULT',
  Dark = 'DARK'
}

export type IUnclearQualityDescriptor = {
  __typename?: 'UnclearQualityDescriptor';
  id: FieldWrapper<Scalars['String']>;
  description: FieldWrapper<Scalars['String']>;
};

export type IUnclearQualityDescriptorResponse = {
  __typename?: 'UnclearQualityDescriptorResponse';
  unclearQualityDescriptors: Array<FieldWrapper<IUnclearQualityDescriptor>>;
};

export type IUnmatchedIndexControl = {
  __typename?: 'UnmatchedIndexControl';
  id: FieldWrapper<Scalars['String']>;
  code: FieldWrapper<Scalars['String']>;
  name: FieldWrapper<Scalars['String']>;
  description: FieldWrapper<Scalars['String']>;
  controlType?: Maybe<FieldWrapper<Scalars['String']>>;
  frequency?: Maybe<FieldWrapper<Scalars['String']>>;
  automation?: Maybe<FieldWrapper<Scalars['String']>>;
  riaDescription?: Maybe<FieldWrapper<IMatchingRiaDescription>>;
  risks: Array<FieldWrapper<IMatchingRisk>>;
  indices: Array<FieldWrapper<IMatchingIndices>>;
  status: FieldWrapper<IUnmatchedIndexControlStatus>;
};

export enum IUnmatchedIndexControlStatus {
  ToConnect = 'TO_CONNECT',
  Missing = 'MISSING',
  Approved = 'APPROVED'
}

export type IUnmatchedIndexControlsInput = {
  accountCode: Scalars['String'];
  matchingJobId: Scalars['String'];
  pagination?: Maybe<IPaginationInput>;
  filters?: Maybe<IFilterInput>;
};

export type IUnmatchedIndexControlsResponse = {
  __typename?: 'UnmatchedIndexControlsResponse';
  controls: Array<FieldWrapper<IUnmatchedIndexControl>>;
  pagination?: Maybe<FieldWrapper<IPageInfo>>;
};

export type IUnresolvedMatchingInfo = {
  __typename?: 'UnresolvedMatchingInfo';
  remainingApprovers: Array<FieldWrapper<IUser>>;
  nextWorkflow?: Maybe<FieldWrapper<IApprovalWorkflow>>;
};

export type IUpdateAccountInput = {
  name: Scalars['String'];
};

export type IUpdateConfiguratorReportInput = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  shareWithOrganisation?: Maybe<Scalars['Boolean']>;
  configuration?: Maybe<Array<IFilterInputGroup>>;
  filters?: Maybe<IFilterInput>;
  table?: Maybe<IConfiguratorReportTableOptionsInput>;
  quickFilterIds?: Maybe<Array<Scalars['String']>>;
};

export type IUpdateEmailNotificationSettingsInput = {
  updatedNotificationCategories: Array<IUpdatedEmailNotificationCategory>;
};

export type IUpdateMatchingJobControlInput = {
  id: Scalars['ID'];
  accountCode: Scalars['String'];
  matchingJobId: Scalars['String'];
  proposed?: Maybe<IMatchingJobProposal>;
  approved?: Maybe<IMatchingJobApproval>;
  metrics?: Maybe<IMatchingJobControlMetrics>;
};

export type IUpdateProposedQuality = {
  descriptorId?: Maybe<Scalars['ID']>;
};

export type IUpdateReportInput = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  shareWithOrganisation?: Maybe<Scalars['Boolean']>;
  configuration?: Maybe<IFilterInput>;
  filters?: Maybe<IFilterInput>;
};

export type IUpdateUnmatchedIndexControlInput = {
  unmatchedIndexControlId: Scalars['ID'];
  accountCode: Scalars['String'];
  matchingJobId: Scalars['String'];
  matchedIds: Array<Scalars['ID']>;
};

export type IUpdateUserInput = {
  name?: Maybe<Scalars['String']>;
  accountId: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
};

export type IUpdatedEmailNotificationCategory = {
  code: Scalars['String'];
  isEnabled: Scalars['Boolean'];
};

export type IUser = {
  __typename?: 'User';
  id: FieldWrapper<Scalars['ID']>;
  name: FieldWrapper<Scalars['String']>;
  email: FieldWrapper<Scalars['String']>;
  permissions?: Maybe<Array<Maybe<FieldWrapper<Scalars['String']>>>>;
  avatar?: Maybe<FieldWrapper<Scalars['String']>>;
  accountId?: Maybe<FieldWrapper<Scalars['String']>>;
  account: FieldWrapper<IAccount>;
  preferences: FieldWrapper<IUserPreferences>;
};

export type IUserAuthorizationResponse = {
  __typename?: 'UserAuthorizationResponse';
  permissions: Array<FieldWrapper<Scalars['String']>>;
};

export type IUserInvitationInput = {
  email: Scalars['String'];
};

export type IUserPreferences = {
  __typename?: 'UserPreferences';
  theme?: Maybe<FieldWrapper<IUiTheme>>;
};

export type IValidateReportNameInput = {
  reportName: Scalars['String'];
  reportId?: Maybe<Scalars['ID']>;
  isSharable: Scalars['Boolean'];
};

export type IValidateReportNameResult = {
  __typename?: 'ValidateReportNameResult';
  isValid: FieldWrapper<Scalars['Boolean']>;
  message?: Maybe<FieldWrapper<Scalars['String']>>;
};
